import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import {Row, Col, Container} from "react-bootstrap";

import {
    activeSigner,
    ADDR_ASSETS_MAP,
    CALL_PUT_SELECTION, compareBnToNumString, depositMint, EXPLORER_TOKEN_URL, EXPLORER_URL,
    formatDecNumber, getPreviewDeposit, getPositionBalance, getUsdcAllowance, importTokens, redeem,
    RISK_VALS, SIDE, usdcApprove, wrapTx,
    ZERO_ADDRESS, SETTLEMENT_TYPE, SETTLEMENT_MSG, getUsdcBalance, toUTC0800Time, ContractType, toLocaleComma
} from "../helper_functions/contractFunctions";
import {BigNumber, ethers} from "ethers";
import {Link} from "react-router-dom";

const ModalRedeem = (props: any) => {
    // const instParams = props.modalValues.instParams
    let [instParams, amountInput, userBalance, longUsdcDue, shortUsdcDue,
        instAddress, longAddress, shortAddress, positionSupply, side, settlementType, currentRefPrice, size] =
        [
            props.modalValues.instParams,
            props.modalValues.amountInput,
            props.modalValues.userBalance,
            props.modalValues.longUsdcDue,
            props.modalValues.shortUsdcDue,
            props.modalValues.instAddress,
            props.modalValues.longAddress,
            props.modalValues.shortAddress,
            props.modalValues.positionSupply,
            props.modalValues.side,
            props.modalValues.settlementType,
            props.modalValues.currentRefPrice,
            props.modalValues.size
        ]

    const [approveDisabled, setApproveDisabled] = useState(false)
    const [depositDisabled, setDepositDisabled] = useState(false)
    const [newAllowance, setNewAllowance] = useState(BigNumber.from(0))

    const [depositCompleted, setDepositCompleted] = useState(false)
    const [newInstAddresses, setNewInstAddresses] = useState(["", "", ""])
    const [newVersion, setNewVersion] = useState(0)
    const [newPosBal, setNewPosBal] = useState(0)

    const redeemPosition = async () => {
        setDepositDisabled(true)
        redeem(instAddress, amountInput.toString(), side).then(
            (tx) => { wrapTx(tx).then(([txReceipt, toastId]) => {
                    toast.update(toastId,{
                        render: "Redemption complete.",
                        type: toast.TYPE.SUCCESS,
                        isLoading: false,
                        autoClose: 3000,
                        hideProgressBar: true
                    })
                    setDepositCompleted(true)
                    setNewInstAddresses([instAddress, longAddress, shortAddress])
                    setNewVersion(instParams.counter)
                    getUsdcBalance().then((numVal) => {setNewPosBal(numVal)})
            })}
        )
    }

    const expired = () => {
        const expiryDateString = (new Date(instParams.year, instParams.month - 1, instParams.day)).toDateString()
        const expiryDate = toUTC0800Time(expiryDateString)
        return Date.now() > expiryDate.getTime()
    }

    const marginCalled = () => {
        // currentRefPrice
        const spread = RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][instParams.thresholdIndex]
        const strike = formatDecNumber(instParams.strikePrice)
        const thresholdPrice = instParams.contractType == ContractType.Call ?
            strike + spread : strike - spread

        const liquidateable = instParams.contractType == ContractType.Call ?
            currentRefPrice > thresholdPrice : currentRefPrice < thresholdPrice

        return liquidateable
    }

    if (instParams != undefined) {
        return (

            <div className='Uniswap'>

                <Modal style={{ fontFamily: 'Montserrat' }}
                       size="xl" show={props.show}
                       onHide={() => {
                        props.handleClose()
                        }}
                       onExited={() => {
                           setApproveDisabled(false)
                           setDepositDisabled(false)
                           setDepositCompleted(false)
                       }}
                >
                    <Modal.Header style={{ 'backgroundColor': "#6c7d6d", 'color': 'white' }}>
                        <Container>
                        <div style={{padding: "0.2rem"}}>
                            <Row className="flex-wrap">
                            <div style={{ 'textAlign': "left" }}>
                                <h2>Redemption Preview</h2><br />
                            </div>
                            </Row>
                            <Row className="flex-wrap">
                                <Col className="modal-col"><span className='h4'>Asset: {ADDR_ASSETS_MAP[instParams.underlyingAsset]} </span></Col>
                                <Col className="modal-col"><span className='h4'>Type: {instParams.contractType == 0 ? CALL_PUT_SELECTION.CALL: CALL_PUT_SELECTION.PUT} </span></Col>
                                <Col className="modal-col"><span className='h4'>Strike: {toLocaleComma(formatDecNumber(instParams.strikePrice), 2)} </span></Col>
                                <Col className="modal-col"><span className='h4'>Expiry: {(new Date(`${instParams.year}-${instParams.month}-${instParams.day}`)).toDateString()
                                    .split(' ').slice(1).join(' ')} </span></Col>
                            </Row>
                            <Row className="flex-wrap modal-row">
                                <Col className="modal-col"><span className='h6'>Risk: {`R${instParams.thresholdIndex + 1}`}</span></Col>
                                <Col className="modal-col"><span className='h6'>Threshold: {
                                    instParams.contractType == 0 ?
                                        toLocaleComma(formatDecNumber(instParams.strikePrice) + RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][instParams.thresholdIndex], 2)
                                        :
                                        (formatDecNumber(instParams.strikePrice) - RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][instParams.thresholdIndex]) < 0 ?
                                            "This instrument cannot be created because the liquidation threshold is negative."
                                            :
                                            toLocaleComma(formatDecNumber(instParams.strikePrice) - RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][instParams.thresholdIndex], 2)
                                } </span></Col>
                                <Col className="modal-col"><span className='h6'>Version: {instParams.counter}</span></Col>
                                <Col className="modal-col"><span className='h6'>Minted Supply: {toLocaleComma(positionSupply,2)}</span></Col>
                                {/*<Col className="modal-col"><span className='h6'>Size: {toLocaleComma(size,2)} USDC</span></Col>*/}
                            </Row>

                            <Row className={"flex-wrap modal-row"}>
                                <Col className="modal-col">
                                <span className='h6' style={{marginLeft: "0rem"}}>
                              Settlement Status: {SETTLEMENT_MSG[settlementType]}
                                </span>
                                </Col>
                            </Row>


                            {(settlementType == SETTLEMENT_TYPE.OTM && side == SIDE.LONG) ||
                            (settlementType == SETTLEMENT_TYPE.Liquidated && side == SIDE.SHORT) ?
                                null
                                :
                                <>
                                    <Row className={"flex-wrap modal-row"}>
                                        <Col className={"modal-col"}>
                                <span className='h6' style={{marginLeft: "0rem"}}>
                                    Redemption Amount: <b>{toLocaleComma(amountInput, 6)}</b> {side == SIDE.LONG ? "LONG" : "SHORT"} Positions</span>

                                        </Col>
                                        <Col className={"modal-col"}>
                                            <span className='h6' style={{marginLeft: "0rem"}}>
                                              {activeSigner != undefined ?
                                                  `From: ${activeSigner._address.slice(0,7)}..${activeSigner._address.slice(-5)}`
                                                  :
                                                  `Please connect wallet to continue.`} </span>
                                        </Col>
                                    </Row>
                                </>
                            }


                          {settlementType == SETTLEMENT_TYPE.NonExistent ?
                              null:
                              settlementType == SETTLEMENT_TYPE.NotSettled ?

                                  (expired() || marginCalled()) ?
                                      <Row className="flex-wrap modal-row">
                                          <Col className="modal-col">
                      <span className='h5' style={{marginLeft: "0rem"}}>
                          This instrument is pending settlement.</span>
                                          <Link to='/settleLiquidate'  state={{ values: props.modalValues }}
                                                style={{ 'color': 'white'}}
                                          ><Button style={{marginTop:"0.5rem"}}
                                              className="button-width" variant="light" size="lg">Settle</Button></Link>

                      </Col></Row>
                                      :
                                      <Row className="flex-wrap modal-row">
                                          <Col className="modal-col">
                      <span className='h5'>
                                      <div>This instrument is still active. Redemptions can only be made after expiry or liquidation.
                                      </div>
                      </span></Col></Row>

                              :                    <Row className="flex-wrap modal-row">
                                      <Col className="modal-col">
                      <span className='h5' style={{marginLeft: "0rem"}}>
                                  {(settlementType == SETTLEMENT_TYPE.OTM && side == SIDE.LONG) ?
                                      `LONGs are not eligible for redemption for OTM instruments.`:
                                      (settlementType == SETTLEMENT_TYPE.Liquidated && side == SIDE.SHORT) ?
                                          `SHORTs are not eligible for redemption for liquidated instruments.`:
                              `You will receive: ${toLocaleComma(side == SIDE.LONG ? longUsdcDue : shortUsdcDue, 6)} USDC.`}
                      </span></Col></Row>
                          }

                        </div >
                        </Container>
                    </Modal.Header>
                    <Modal.Body style={{ 'backgroundColor': "#6c7d6d", 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', 'color': 'white', 'flexDirection': 'column' }}>
                        <Container>
                        {depositCompleted || (instAddress == ZERO_ADDRESS) ?
                            <Row className={"flex-wrap"}>
                                <Button className="button-width" variant="dark" size="lg"
                                        onClick={() => {
                                            props.handleClose()
                                        }}
                                >Close</Button>
                            </Row>
                            :<>
                            <Row className={"flex-wrap"}>
                                <Col className="modal-col-borderless">
                                    <Button className="button-width" variant="light" size="lg"
                                            disabled={depositDisabled || (amountInput <= 0 || (settlementType==0) || (settlementType==4) ||
                                                (settlementType==SETTLEMENT_TYPE.OTM && side==SIDE.LONG) ||
                                                (settlementType==SETTLEMENT_TYPE.Liquidated && side==SIDE.SHORT) ||
                                                (side == SIDE.LONG ? longUsdcDue : shortUsdcDue) <= 0
                                            )}
                                            onClick={() => {
                                                redeemPosition()
                                            }}
                                    >Redeem</Button>
                                </Col></Row>
                                {
                                    <Row className={"flex-wrap"}>
                                        <Col className="modal-col-borderless">
                                            <Button className="button-width" variant="dark" size="lg"
                                                    onClick={() => {
                                                        props.handleClose()
                                                    }}
                                            >Close</Button>
                                        </Col></Row>
                                }
                            </>
                        }

                        { depositCompleted ?
                            <>
                                <Row className={"flex-wrap"}>
                                    <Col className="modal-col-borderless h5">Redemption completed. Your new balance is : {toLocaleComma(newPosBal, 6)} USDC.</Col>
                                </Row>
                                <Row className={"flex-wrap modal-row"} >
                                    <Col className={"modal-col-borderless-sm"}> <span className={"h6"}>
                                      View on explorer: </span>
                                    </Col>
                                </Row><Row className="flex-wrap">
                                    <Col className={"modal-col-borderless-sm"}><span className={"h6"}>
                                      <a href={`${EXPLORER_URL}${newInstAddresses[0]}`} target="_blank"
                                         style={{color: "lightblue"}}> Instrument
                                            </a>
                                  </span></Col>
                                    <Col className={"modal-col-borderless-sm"}><span className={"h6"}>
                                      <a href={`${EXPLORER_TOKEN_URL}${newInstAddresses[1]}`} target="_blank"
                                         style={{color: "lightblue"}}> Long Position
                                            </a>
                                  </span></Col>
                                    <Col className={"modal-col-borderless-sm"}><span className={"h6"}>
                                      <a href={`${EXPLORER_TOKEN_URL}${newInstAddresses[2]}`} target="_blank"
                                         style={{color: "lightblue"}}> Short Position
                                            </a>
                                  </span></Col>
                                </Row>

                                {/*<Row className={"flex-wrap"}>*/}
                                {/*    <Col className="modal-col-borderless">*/}
                                {/*        <Button className="button-width" variant="outline-light" size="sm" onClick={() => {*/}
                                {/*            importTokens(longAddress, shortAddress)*/}
                                {/*        }}>*/}
                                {/*            Import position tokens to Metamask assets list.*/}
                                {/*        </Button>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                            </>
                            :
                            instAddress == ZERO_ADDRESS ?
                                <Row className={"flex-wrap"}>
                                    <Col className="modal-col-borderless">
                                    <span className='h5'>This instrument has not been created.</span>
                                    </Col>
                                </Row>
                                :
                                <>
                                    <Row className={"flex-wrap"}>
                                        <Col className="modal-col-borderless">
                                            <span className={"h6"}> View on explorer: </span>
                                        </Col>
                                    </Row><Row className="flex-wrap">
                                        <Col className="modal-col-borderless">
                                          <span className={"h6"}>
                                              <a href={`${EXPLORER_URL}${instAddress}`} target="_blank"
                                                 style={{color: "lightblue"}}> Instrument
                                                    </a>
                                          </span>
                                        </Col>
                                        <Col className="modal-col-borderless">
                                          <span className={"h6"}>
                                              <a href={`${EXPLORER_TOKEN_URL}${longAddress}`} target="_blank"
                                                 style={{color: "lightblue"}}> Long Position
                                                    </a>
                                                      </span>
                                        </Col>
                                        <Col className="modal-col-borderless">
                                          <span className={"h6"}>
                                              <a href={`${EXPLORER_TOKEN_URL}${shortAddress}`} target="_blank"
                                                 style={{color: "lightblue"}}> Short Position
                                                </a>
                                          </span>
                                        </Col>
                                    </Row>

                                    {/*<Row className="flex-wrap">*/}
                                    {/*    <Col className="modal-col-borderless">*/}
                                    {/*        <Button className="button-width" variant="outline-light" size="sm" onClick={() => {*/}
                                    {/*            importTokens(longAddress, shortAddress)*/}
                                    {/*        }}>*/}
                                    {/*            Import position tokens to Metamask assets list*/}
                                    {/*        </Button>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                </>
                        }
                        </Container>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }

    return (
        <>
        </>
    )

}
export default ModalRedeem;

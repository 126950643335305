
import { Navbar, Container, Nav } from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';
import { Web3Button } from "@web3modal/react";
import {useEffect, useState} from "react";
import { ACTIVE_KEY, TAB_KEYS } from "../helper_functions/contractFunctions";

const Navigation = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('home');

  useEffect(() => {
    // Set the active tab based on the current location
    switch (location.pathname) {
      case '/':
        setActiveTab(TAB_KEYS.home);
        break;
      case '/myPositions':
        setActiveTab(TAB_KEYS.positions);
        break;
      case '/mint':
        setActiveTab(TAB_KEYS.mint);
        break;
      case '/redeem':
        setActiveTab(TAB_KEYS.redeem);
        break;
      case '/settleLiquidate':
        setActiveTab(TAB_KEYS.settle);
        break;
      case '/withdraw':
        setActiveTab(TAB_KEYS.withdraw);
        break;
      default:
        setActiveTab(TAB_KEYS.home);
    }
  }, [location.pathname]);

  const activeStyle = {
    fontWeight: "bolder",
    textDecoration: "underline"
  }

  const inactiveStyle = {
    fontWeight: "normal",
    textDecoration: "none"
  }

  useEffect(() => {
    localStorage.setItem(ACTIVE_KEY, localStorage.getItem(ACTIVE_KEY) || 'home');
  }, [activeTab]);
  return (
    <div style={{ fontFamily: 'Montserrat' }}>
        <Container fluid>
          <Navbar bg="secondary" expand="md" style={{
            padding: "0.5rem",
          }}>

          <Navbar.Brand href="/"
          style={{
            // marginRight: "5rem",
            // marginLeft: "-3rem",
            // paddingRight: "0rem",
            width: "50%",
          }}
          >
            <img src={"logo.png"} alt="logo" style={{
              display: "inline-block",
              width: "80%",
              maxWidth: "350px",
              minWidth: "200px",
              height: "100%",
              marginRight: "0rem",
              marginBottom: "0rem",
              padding: "0rem",
            }}
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end"

          >
            <Nav activeKey={localStorage.getItem(ACTIVE_KEY) as string}>
              <Nav.Link eventKey={TAB_KEYS.home}>
                <Link style={activeTab == 'home'? activeStyle : inactiveStyle}
                  to="/" className='navbarlink '>Dashboard</Link>
              </Nav.Link>
              <Nav.Link eventKey={TAB_KEYS.mint}>
                <Link style={activeTab == 'mint'? activeStyle : inactiveStyle}
                    to="/mint" className='navbarlink'>Mint</Link>
              </Nav.Link>
              <Nav.Link eventKey={TAB_KEYS.redeem}>
                <Link style={activeTab == 'redeem'? activeStyle : inactiveStyle}
                    to="/redeem" className='navbarlink'>Redeem</Link>
              </Nav.Link>
              <Nav.Link eventKey={TAB_KEYS.settle}>
                <Link style={activeTab == 'settle'? activeStyle : inactiveStyle}
                    to="/settleLiquidate" className='navbarlink'>Settle</Link>
              </Nav.Link>
              <Nav.Link eventKey={TAB_KEYS.withdraw}>
                <Link style={activeTab == 'withdraw'? activeStyle : inactiveStyle}
                    to='/withdraw' className='navbarlink'>Withdraw</Link>
              </Nav.Link>
              <Nav.Link eventKey={TAB_KEYS.positions}>
                <Link style={activeTab == 'positions'? activeStyle : inactiveStyle}
                      to="/myPositions" className='navbarlink '>Positions</Link>
              </Nav.Link>
              <Nav.Link href="_">
                <a id="docs" href="https://docs.volorca.com" target="_blank" rel="noopener noreferrer"
                   className='navbarlink fw-normal' style={{marginRight: "0.5rem"}}
                >Docs</a>
              </Nav.Link>
            </Nav>
            <Web3Button />
          </Navbar.Collapse>
          </Navbar>
        </Container>
    </div>
  )
}

export default Navigation;
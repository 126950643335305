import { useContractRead } from 'wagmi'
import { BigNumber } from "ethers"
import {CONFIG, FACTORY_ABI, HELPER_ABI, PRICE_REFERENCE_ABI, ERC20_ABI, INST_ABI, Status} from "./configuration";
import {ethereumClient, wagmiClient} from "./connection";
import {ethers} from "ethers";
import { toast } from "react-toastify";
import React from "react";
import {polygonMumbai} from "wagmi/chains";

declare global {
    interface Window { // @ts-ignore
        ethereum: any }
}

export const MS_DAY = 1000 * 24 * 60 * 60
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000"
export const EXPLORER_URL = "https://mumbai.polygonscan.com/address/"
export const EXPLORER_TOKEN_URL = "https://mumbai.polygonscan.com/token/"
export const EXPLORER_TX_URL = "https://mumbai.polygonscan.com/tx/"
export const STEP_SIZE: {[key: string]: number} = {
    "BTC": 2000, "ETH": 200, "MATIC": 0.2
}

export const DP: {[key: string]: number} = {
    "BTC": 0, "ETH": 0, "MATIC": 1
}

export enum SIDE { LONG, SHORT}

export enum SETTLEMENT_TYPE { NotSettled, OTM, ITM, Liquidated, NonExistent }

export const SETTLEMENT_MSG: {[key: number]: string} = {
    0: "Not Settled.",
    1: "Out-of-The-Money (OTM)",
    2: "In-The-Money (ITM)",
    3: "Liquidated",
    4: "Instrument does not exist."
}

export const RISK_VALS: {[key: string]: number[]} = {
    "BTC": [2000, 5000, 12000],
    "ETH": [200, 500, 1200],
    "MATIC": [0.2, 0.5, 1.2]
}

export const RISK_IDX: {[key: string]: {[key2: number]: number}} = {
    "BTC": {2000: 0, 5000: 1, 12000: 2},
    "ETH": {200: 0, 500: 1, 1200: 2},
    "MATIC": {0.2: 0, 0.5: 1, 1.2: 2}
}

export interface InstParams {
    underlyingAsset: `0x${string}`;
    contractType: ContractType;
    strikePrice: BigNumber;
    thresholdIndex: number;
    year: number;
    month: number;
    day: number;
    counter: number
}

export interface PositionInfo {
    instParams: InstParams;
    side: SIDE;
    amount: number;
    address: string;
    thresholdPrice: BigNumber;
    assetName: string;
}

export interface PositionInfoWithRedeemable {
    instParams: InstParams;
    side: SIDE;
    amount: number;
    address: string;
    thresholdPrice: BigNumber;
    assetName: string;
    redeemable: number;
}

export interface TimeRemainingObject {
    days: number, hours: number, minutes: number
}

export enum ContractType { Call, Put }

export const ACTIVE_KEY = "activeTab"
export const TAB_KEYS = {
    home: "home",
    positions: "positions",
    mint: "mint",
    redeem: "redeem",
    settle: "settle",
    withdraw: "withdraw"
}

export interface RowObject {
    nR3Short: string; nR3Long: string; nR2Short: string; nR2Long: string; nR1Short: string; nR1Long: string;
    pR1Short: string; pR1Long: string; pR2Short: string; pR2Long: string; pR3Short: string; pR3Long: string;

    nR3ShortVersion: string; nR3LongVersion: string; nR2ShortVersion: string; nR2LongVersion: string; nR1ShortVersion: string; nR1LongVersion: string;
    pR1ShortVersion: string; pR1LongVersion: string; pR2ShortVersion: string; pR2LongVersion: string; pR3ShortVersion: string; pR3LongVersion: string;

    nR3ShortSupply: string; nR3LongSupply: string; nR2ShortSupply: string; nR2LongSupply: string; nR1ShortSupply: string; nR1LongSupply: string;
    pR1ShortSupply: string; pR1LongSupply: string; pR2ShortSupply: string; pR2LongSupply: string; pR3ShortSupply: string; pR3LongSupply: string;

    nR3ShortAddress: string; nR3LongAddress: string; nR2ShortAddress: string; nR2LongAddress: string; nR1ShortAddress: string; nR1LongAddress: string;
    pR1ShortAddress: string; pR1LongAddress: string; pR2ShortAddress: string; pR2LongAddress: string; pR3ShortAddress: string; pR3LongAddress: string;

    nR3ShortSymbol: string; nR3LongSymbol: string; nR2ShortSymbol: string; nR2LongSymbol: string; nR1ShortSymbol: string; nR1LongSymbol: string;
    pR1ShortSymbol: string; pR1LongSymbol: string; pR2ShortSymbol: string; pR2LongSymbol: string; pR3ShortSymbol: string; pR3LongSymbol: string;

    nR3ShortSettlementType: number; nR3LongSettlementType: number; nR2ShortSettlementType: number; nR2LongSettlementType: number; nR1ShortSettlementType: number; nR1LongSettlementType: number;
    pR1ShortSettlementType: number; pR1LongSettlementType: number; pR2ShortSettlementType: number; pR2LongSettlementType: number; pR3ShortSettlementType: number; pR3LongSettlementType: number;
}

export enum STATE { STATE0, STATE1, STATE2 }
export enum R_TAG { NEG_R3, NEG_R2, NEG_R1, POS_R1, POS_R2, POS_R3 }


export const ASSETS = {
    BTC: "BTC", ETH: "ETH", MATIC: "MATIC"
}
export const CALL_PUT_SELECTION = {
    CALL: "CALL", PUT: "PUT"
}

const helperReader = new ethers.Contract(CONFIG.HELPER_ADDRESS, HELPER_ABI, wagmiClient.provider)
const priceReader = new ethers.Contract(CONFIG.PRICE_REF_UNISWAP_ADDRESS, PRICE_REFERENCE_ABI, wagmiClient.provider)
const usdcReader = new ethers.Contract(CONFIG.USDC_ADDRESS, ERC20_ABI, wagmiClient.provider)
const factoryReader = new ethers.Contract(CONFIG.FACTORY_ADDRESS, FACTORY_ABI, wagmiClient.provider)

let usdcTransactor: ethers.Contract = usdcReader
let factoryTransactor: ethers.Contract = factoryReader
export let activeSigner: any | undefined = undefined

const SETTLEMENT_DELAY = 60 // 3600
const CHUNK = 100
export const NA_STRING = "---"
const DEFAULT_NA_OBJ = Array(12).fill(NA_STRING)
const DEFAULT_NA_NUM_OBJ = Array(12).fill(4)
const MTH2STR: {[key: number]: string} = {1: "JAN", 2: "FEB", 3: "MAR", 4: "APR", 5: "MAY", 6: "JUN",
                7: "JUL", 8: "AUG", 9: "SEP", 10: "OCT", 11: "NOV", 12: "DEC"}
export const MTH2DIGITS: {[key: string]: string} = {"JAN": "01", "FEB": "02", "MAR": "03", "APR": "04", "MAY": "05", "JUN": "06",
    "JUL": "07", "AUG": "08", "SEP": "09", "OCT": "10", "NOV": "11", "DEC": "12"}

export const ASSETS_ADDR_MAP: {[key: string]: `0x${string}`} = {
    "BTC": CONFIG.BTC_ADDRESS as `0x${string}`,
    "ETH": CONFIG.ETH_ADDRESS as `0x${string}`,
    "MATIC": CONFIG.MATIC_ADDRESS as `0x${string}`
}

const flip = (data: { [s: string]: unknown; } | ArrayLike<unknown>) => Object.fromEntries(
    Object
        .entries(data)
        .map(([key, value]) => [value, key])
);

export const ADDR_ASSETS_MAP = flip(ASSETS_ADDR_MAP)

function toDateString(date: number) {
    const day = (date % 100)
    const month = ((date - day) / 100) % 100
    const year = (date - day - (month * 100)) / 10000
    const dayString = day < 10 ? "0" + day.toFixed(0): day.toFixed(0)
    return `${dayString} ${MTH2STR[month]} ${"20" + year.toFixed(0)}`
}

function fromDateString(date: string) {
    const splitDate: string[] = date.split(" ", 3)
    const day = splitDate[0]
    let month = splitDate[1]
    let year = splitDate[2]
    month = MTH2DIGITS[month]
    year = year.slice(2, 4)
    const dateDigits = `${year}${month}${day}`
    return BigNumber.from(dateDigits)
}

export function toCommaFormat(num: string) {
    return num != undefined ? num.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "~"
}

export function toLocaleComma(num: number, maxDp = 6) {
    return num != undefined ? num.toLocaleString(undefined, {maximumFractionDigits: maxDp}) : "~"
}

export function dateToUtc(localdate: Date) {
    return new Date(localdate.getUTCFullYear(), localdate.getUTCMonth(), localdate.getUTCDate(),
        localdate.getUTCHours(), localdate.getUTCMinutes(), localdate.getUTCSeconds())
}

export function toUTC0800Time(date: string) {
    const utc8Date = new Date(`${date} 08:00:00 UTC`)
    return utc8Date
}

export function fromDateStringToYMD(date: string) {
    const splitDate: string[] = date.split(" ", 3)
    const year = Number(splitDate[2])
    const month = Number(MTH2DIGITS[splitDate[1]])
    const day = Number(splitDate[0])
    return [year, month, day]
}

export async function getDatesForUnderlying(status: Status, asset: string) {
    let startIdx = 0
    let endIdx = CHUNK
    const assetAddress = ASSETS_ADDR_MAP[asset.toUpperCase()]
    let dates: BigNumber[] = []

    while (true) {
        const response: BigNumber[] = await helperReader.getDatesForUnderlying(status, assetAddress, startIdx, endIdx)
        dates = dates.concat(response)
        if (response.length < CHUNK) { break }
        startIdx = endIdx
        endIdx = startIdx + CHUNK
    }

    const numericDates: number[] = dates.map(x => { return x.toNumber() })
    const sortedDates: number[] = numericDates.sort((a, b) => { return a - b })
    return sortedDates.map( x => { return toDateString(x) })
}

export function positionParser(symbol: string) {
    const year = "20".concat(symbol.slice(0, 2))
    const month = symbol.slice(2, 4)
    const day = symbol.slice(4, 6)
    const letter = symbol.slice(6, 7)
    const asset = letter == "B" ? "BTC" : letter == "E" ? "ETH" : "MATIC"
    const secondSection = symbol.split(asset)[1]
    const [strike, thirdSection] = secondSection.includes("C") ? secondSection.split("C"): secondSection.split("P")
    const contractType = secondSection.includes("C") ? ContractType.Call : ContractType.Put
    const [threshold, forthSection] = thirdSection.split("V")
    const [version, side] = forthSection.split("-")

    const strikeThreshSpread = Number(Math.abs(Number(strike) - Number(threshold)).toFixed(2))

    const instParams: InstParams = {
        underlyingAsset: ASSETS_ADDR_MAP[asset],
        year: Number(year), month: Number(month), day: Number(day),
        thresholdIndex: RISK_IDX[asset][strikeThreshSpread], contractType: contractType,
        strikePrice: convertToBN(strike), counter: Number(version)
    }

    return [instParams, side == "LONG" ? SIDE.LONG: SIDE.SHORT, convertToBN(threshold), asset]
}


export async function getPositionBalances() {
    await refreshWallet()
    let startIdx = 0
    let endIdx = CHUNK
    let symbols: string[] = []
    let addresses: string[] = []
    let balances: BigNumber[] = []
    let redeemable: BigNumber[] = []
    let positionInfoList: PositionInfoWithRedeemable[] = []

    if (activeSigner != undefined) {

        while (true) {
            const [symbolsResp, addressesResp, balancesResp, redeemableResp] = await helperReader.getPositionBalances(activeSigner._address, startIdx, endIdx)
            symbols = symbols.concat(symbolsResp)
            addresses = addresses.concat(addressesResp)
            balances = balances.concat(balancesResp)
            redeemable = redeemable.concat(redeemableResp)

            if (symbolsResp.length < CHUNK) {
                break
            }
            startIdx = endIdx
            endIdx = startIdx + CHUNK
        }


        for (let i = 0; i < symbols.length; i++) {
            if (symbols[i].length > 0) {
                const [instParams, side, thresholdPrice, assetName] = positionParser(symbols[i])
                const balance = formatDecNumber(balances[i])
                const redeemableAmt = formatDecNumber(redeemable[i])
                positionInfoList.push({
                    instParams: instParams as InstParams,
                    side: side as SIDE,
                    address: addresses[i],
                    amount: balance,
                    assetName: assetName as string,
                    thresholdPrice: thresholdPrice as BigNumber,
                    redeemable: redeemableAmt
                })
            }
        }

        positionInfoList.sort((a, b) => {
            const [yearA, yearB] = [a.instParams.year, b.instParams.year]
            const [monthA, monthB] = [a.instParams.month, b.instParams.month]
            const [dayA, dayB] = [a.instParams.day, b.instParams.day]
            const [assetA, assetB] = [a.assetName, b.assetName]
            const [strikeA, strikeB] = [formatDecNumber(a.instParams.strikePrice), formatDecNumber(b.instParams.strikePrice)]
            const [thresholdA, thresholdB] = [formatDecNumber(a.thresholdPrice), formatDecNumber(b.thresholdPrice)]
            const [versionA, versionB] = [a.instParams.counter, b.instParams.counter]
            const [contractA, contractB] = [a.instParams.contractType, b.instParams.contractType]
            const [sideA, sideB] = [a.side, b.side]

            if (yearA != yearB) {
                return yearA - yearB
            } else {
                if (monthA != monthB) {
                    return monthA - monthB
                } else {
                    if (dayA != dayB) {
                        return dayA - dayB
                    } else {
                        if (assetA != assetB) {
                            return assetA.localeCompare(assetB)
                        } else {
                            if (strikeA != strikeB) {
                                return strikeA - strikeB
                            } else {
                                if (thresholdA != thresholdB) {
                                    return thresholdA - thresholdB
                                } else {
                                    if (versionA != versionB) {
                                        return versionA - versionB
                                    } else {
                                        if (contractA != contractB) {
                                            return contractA - contractB
                                        } else {
                                            if (sideA != sideB) {
                                                return sideA - sideB
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return 0
        })
    }
    return positionInfoList
}

export function formatDecNumber(decNumber: BigNumber) {
    return Number(ethers.utils.formatUnits(decNumber, 6))
}

export function convertToBN(decNumber: string | number) {
    let newNumber: number;
    if (typeof decNumber == "string"){
        newNumber = Number(decNumber)
    } else {
        newNumber = decNumber
    }
    const newNumberStr = newNumber.toFixed(6)
    return ethers.utils.parseUnits(newNumberStr, 6)
}

export async function getPrices(asset: string, date: string) {
    let startIdx = 0
    let endIdx = CHUNK
    const assetAddress = ASSETS_ADDR_MAP[asset.toUpperCase()]
    const dateId = fromDateString(date)
    let objects: any[][] = []
    let strikeObjs = new Map<number, string[]>()
    let versionMap = new Map<number, string[]>()
    let supplyMap = new Map<number, string[]>()
    let addressesMap = new Map<number, string[]>()
    let symbolsMap = new Map<number, string[]>()
    let settlementTypeMap = new Map<number, number[]>()
    let strikes = new Set<number>()

    while (true) {
        const response: BigNumber[][] = await helperReader.getPrices(assetAddress, dateId, startIdx, endIdx)
        objects = objects.concat(response)
        response.forEach(value => { strikes.add(formatDecNumber(value[0])) })
        if (response.length < CHUNK) { break }
        startIdx = endIdx
        endIdx = startIdx + CHUNK
    }

    // Initialize 2d array
    // console.log(strikes)
    strikes.forEach( stk => {
        strikeObjs.set(stk, [ ...DEFAULT_NA_OBJ ])
        versionMap.set(stk, [ ...DEFAULT_NA_OBJ ])
        supplyMap.set(stk, [ ...DEFAULT_NA_OBJ ])
        addressesMap.set(stk, [ ...DEFAULT_NA_OBJ ])
        symbolsMap.set(stk, [ ...DEFAULT_NA_OBJ ])
        settlementTypeMap.set(stk, [ ...DEFAULT_NA_NUM_OBJ ])
    })
    objects.forEach(obj => {
        const stk = formatDecNumber(obj[0])
        const buyCall: number = Number(obj[1])
        const risk: number = Number(obj[2])
        const longIdx = buyCall == 0 ? 7 + (risk * 2) : 5 - (risk * 2)
        const shortIdx = buyCall == 0 ? 6 + (risk * 2) : 4 - (risk * 2)
        const longPrice = obj[7]
        if (longPrice.gt(BigNumber.from(0))) {
            let prices = strikeObjs.get(stk)!!
            prices[longIdx] = toLocaleComma(Number(ethers.utils.formatUnits(longPrice, 6)), 3)
            strikeObjs.set(stk, prices)
        }


        const shortPrice = obj[8]
        if (shortPrice.gt(BigNumber.from(0))) {
            let prices = strikeObjs.get(stk)!!
            prices[shortIdx] = toLocaleComma(Number(ethers.utils.formatUnits(shortPrice, 6)), 3)
            strikeObjs.set(stk, prices)
        }

        const version = obj[5]
        if (Number(version) > 0) {
            let versions = versionMap.get(stk)!!
            versions[longIdx] = version.toString()
            versions[shortIdx] = version.toString()
            versionMap.set(stk, versions)
        }

        const positionSupply = obj[6]
        if (positionSupply.gt(BigNumber.from(0))) {
            let supplies = supplyMap.get(stk)!!
            supplies[longIdx] = ethers.utils.formatUnits(positionSupply, 6)
            supplies[shortIdx] = ethers.utils.formatUnits(positionSupply, 6)
            supplyMap.set(stk, supplies)
        }
        const longAddress = obj[3]
        const shortAddress = obj[4]
        let addresses = addressesMap.get(stk)!!
        addresses[longIdx] = longAddress
        addresses[shortIdx] = shortAddress
        addressesMap.set(stk, addresses)

        const longSymbol = obj[9]
        const shortSymbol = obj[10]
        let symbols = symbolsMap.get(stk)!!
        symbols[longIdx] = longSymbol
        symbols[shortIdx] = shortSymbol
        symbolsMap.set(stk, symbols)

        const settlementType = obj[11]
        let sts = settlementTypeMap.get(stk)!!
        sts[longIdx] = settlementType
        sts[shortIdx] = settlementType
        settlementTypeMap.set(stk, sts)
    })

    let returnObj: { [id: string]: RowObject } = {}
    strikes.forEach(stk => {
        const thisObject = strikeObjs.get(stk)!!
        const thisVersion = versionMap.get(stk)!!
        const thisPositionSupply = supplyMap.get(stk)!!
        const thisAddress = addressesMap.get(stk)!!
        const thisSymbol = symbolsMap.get(stk)!!
        const thisSettlementType = settlementTypeMap.get(stk)!!
        const stkName = asset == "MATIC" ? stk.toFixed(1) : stk.toFixed(0)
        returnObj[stkName] = {
            nR3Short: thisObject[0],
            nR3Long: thisObject[1],
            nR2Short: thisObject[2],
            nR2Long: thisObject[3],
            nR1Short: thisObject[4],
            nR1Long: thisObject[5],
            pR1Short: thisObject[6],
            pR1Long: thisObject[7],
            pR2Short: thisObject[8],
            pR2Long: thisObject[9],
            pR3Short: thisObject[10],
            pR3Long: thisObject[11],

            nR3ShortVersion: thisVersion[0],
            nR3LongVersion: thisVersion[1],
            nR2ShortVersion: thisVersion[2],
            nR2LongVersion: thisVersion[3],
            nR1ShortVersion: thisVersion[4],
            nR1LongVersion: thisVersion[5],
            pR1ShortVersion: thisVersion[6],
            pR1LongVersion: thisVersion[7],
            pR2ShortVersion: thisVersion[8],
            pR2LongVersion: thisVersion[9],
            pR3ShortVersion: thisVersion[10],
            pR3LongVersion: thisVersion[11],

            nR3ShortSupply: thisPositionSupply[0],
            nR3LongSupply: thisPositionSupply[1],
            nR2ShortSupply: thisPositionSupply[2],
            nR2LongSupply: thisPositionSupply[3],
            nR1ShortSupply: thisPositionSupply[4],
            nR1LongSupply: thisPositionSupply[5],
            pR1ShortSupply: thisPositionSupply[6],
            pR1LongSupply: thisPositionSupply[7],
            pR2ShortSupply: thisPositionSupply[8],
            pR2LongSupply: thisPositionSupply[9],
            pR3ShortSupply: thisPositionSupply[10],
            pR3LongSupply: thisPositionSupply[11],

            nR3ShortAddress: thisAddress[0],
            nR3LongAddress: thisAddress[1],
            nR2ShortAddress: thisAddress[2],
            nR2LongAddress: thisAddress[3],
            nR1ShortAddress: thisAddress[4],
            nR1LongAddress: thisAddress[5],
            pR1ShortAddress: thisAddress[6],
            pR1LongAddress: thisAddress[7],
            pR2ShortAddress: thisAddress[8],
            pR2LongAddress: thisAddress[9],
            pR3ShortAddress: thisAddress[10],
            pR3LongAddress: thisAddress[11],

            nR3ShortSymbol: thisSymbol[0],
            nR3LongSymbol: thisSymbol[1],
            nR2ShortSymbol: thisSymbol[2],
            nR2LongSymbol: thisSymbol[3],
            nR1ShortSymbol: thisSymbol[4],
            nR1LongSymbol: thisSymbol[5],
            pR1ShortSymbol: thisSymbol[6],
            pR1LongSymbol: thisSymbol[7],
            pR2ShortSymbol: thisSymbol[8],
            pR2LongSymbol: thisSymbol[9],
            pR3ShortSymbol: thisSymbol[10],
            pR3LongSymbol: thisSymbol[11],

            nR3ShortSettlementType: thisSettlementType[0],
            nR3LongSettlementType: thisSettlementType[1],
            nR2ShortSettlementType: thisSettlementType[2],
            nR2LongSettlementType: thisSettlementType[3],
            nR1ShortSettlementType: thisSettlementType[4],
            nR1LongSettlementType: thisSettlementType[5],
            pR1ShortSettlementType: thisSettlementType[6],
            pR1LongSettlementType: thisSettlementType[7],
            pR2ShortSettlementType: thisSettlementType[8],
            pR2LongSettlementType: thisSettlementType[9],
            pR3ShortSettlementType: thisSettlementType[10],
            pR3LongSettlementType: thisSettlementType[11],
        }
    })

    return returnObj
}

export async function getCurrentPrice(asset: string) {
    const assetAddress = ASSETS_ADDR_MAP[asset.toUpperCase()]
    return priceReader.getCurrentPrice(SETTLEMENT_DELAY, assetAddress)
}

export async function getRiskVals(asset: string) {
    const assetIdx = asset.toUpperCase() == "BTC" ? 0 :
                        asset.toUpperCase() == "ETH" ? 1 : 2
    const decPlaces = asset.toUpperCase() == "MATIC" ? 2: 0
    const underlyings = await helperReader.getUnderlyings()
    const thresholdsBn: BigNumber[] = underlyings[assetIdx][9]
    const negOne = BigNumber.from(-1)
    const riskVals = [negOne.mul(thresholdsBn[2]), negOne.mul(thresholdsBn[1]), negOne.mul(thresholdsBn[0]),
        ...thresholdsBn]

    return riskVals.map( x => {
        const formatted = ethers.utils.formatUnits(x, 6)
        return Number(formatted).toFixed(decPlaces)
    })
}

export async function getMySigner() {
    return ethereumClient.getAccount().connector?.getSigner();
}

export async function refreshWallet() {
    const signer = await getMySigner()

    if (signer != activeSigner) {
        if (signer == undefined) {
            usdcTransactor = usdcReader
            factoryTransactor = factoryReader
        } else {
            usdcTransactor = usdcReader.connect(signer)
            factoryTransactor = factoryReader.connect(signer)
        }
        activeSigner = signer
    }
}

export async function getUsdcBalance(): Promise<number> {
    await refreshWallet()
    return activeSigner == undefined ? 0 :
        Number(ethers.utils.formatUnits(await usdcReader.balanceOf(activeSigner._address), 6))
}

export async function getInstrumentSize(address: string): Promise<number> {
    const positionReader = new ethers.Contract(address, INST_ABI, wagmiClient.provider)
    return Number(ethers.utils.formatUnits(positionReader.size(), 6))
}

export async function getPositionBalance(address: string): Promise<number> {
    await refreshWallet()
    const positionReader = new ethers.Contract(address, ERC20_ABI, wagmiClient.provider)

    return activeSigner == undefined ? 0 :
        Number(ethers.utils.formatUnits(await positionReader.balanceOf(activeSigner._address), 6))
}

export async function getPosBalancesFromParams(instParams: InstParams) {
    await refreshWallet()
    const paramsArray = instParamsContructor(instParams)
    if (activeSigner == undefined) {
        return [0, 0]
    } else {
        let [longBal, shortBal] = await helperReader.getPosBalancesFromParams(paramsArray, activeSigner._address)
        longBal = formatDecNumber(longBal)
        shortBal = formatDecNumber(shortBal)
        return [longBal, shortBal]
    }
}

export async function getUsdcAllowance(): Promise<BigNumber> {
    await refreshWallet()
    return activeSigner == undefined ? BigNumber.from(0) :
        await usdcReader.allowance(activeSigner._address, CONFIG.FACTORY_ADDRESS)
}

export function compareBnToNumString(bn: BigNumber, ns: string) {
    /*
    @dev: true if bn >= ns.
     */
    const bnNum = ethers.utils.parseUnits(ns, 6)
    return bn.gte(bnNum)
}

function instParamsContructor(instParams: InstParams) {
    let params = [];
    params.push(instParams.underlyingAsset)
    params.push(instParams.contractType)
    params.push(instParams.strikePrice)
    params.push(instParams.thresholdIndex)
    params.push(instParams.year)
    params.push(instParams.month)
    params.push(instParams.day)
    params.push(instParams.counter)
    return params
}

export async function getPreviewSettle(instParams: InstParams) {
    const paramsArray = instParamsContructor(instParams)
    let [counter, amountTokens, instAddress, longAddress, shortAddress, positionSupply, settlementType]
        = await helperReader.getPreviewDeposit(1000, paramsArray)

    return [instAddress, settlementType]
}

export async function getPreviewFull(amountPosition: string, instParams: InstParams) {
    const amountTokens = ethers.utils.parseUnits(amountPosition, 6)
    const paramsArray = instParamsContructor(instParams)
    let [longUsdcDue, shortUsdcDue, instAddress, longAddress, shortAddress, positionSupply, settlementType]
        = await helperReader.getPreviewRedeem(amountTokens, paramsArray)

    longUsdcDue = formatDecNumber(longUsdcDue)
    shortUsdcDue = formatDecNumber(shortUsdcDue)
    positionSupply = formatDecNumber(positionSupply)

    let size = 0;
    if (instAddress != ethers.constants.AddressZero) {
        const positionReader = new ethers.Contract(instAddress, INST_ABI, wagmiClient.provider)
        size = formatDecNumber(await positionReader.size())
    }

    return [longUsdcDue, shortUsdcDue, instAddress, longAddress, shortAddress, positionSupply, settlementType, size]
}

export async function getPreviewDeposit(amountUsdc: string, instParams: InstParams) {
    const amountCash = ethers.utils.parseUnits(amountUsdc, 6)
    const paramsArray = instParamsContructor(instParams)
    let [counter, amountTokens, instAddress, longAddress, shortAddress, positionSupply, settlementType]
        = await helperReader.getPreviewDeposit(amountCash, paramsArray)

    amountTokens = formatDecNumber(amountTokens)
    positionSupply = ethers.utils.formatUnits(positionSupply, 6)

    return [counter, amountTokens, instAddress, longAddress, shortAddress, positionSupply]
}

export async function getPreviewRedeem(amountPosition: string, instParams: InstParams) {
    const amountTokens = ethers.utils.parseUnits(amountPosition, 6)
    const paramsArray = instParamsContructor(instParams)

    let [longUsdcDue, shortUsdcdue, instAddress, longAddress, shortAddress, positionSupply, settlementType]
        = await helperReader.getPreviewRedeem(amountTokens, paramsArray)
    longUsdcDue = formatDecNumber(longUsdcDue)
    shortUsdcdue = formatDecNumber(shortUsdcdue)
    positionSupply = ethers.utils.formatUnits(positionSupply, 6)

    return [longUsdcDue, shortUsdcdue, instAddress, longAddress, shortAddress, positionSupply, settlementType]
}

export async function getPreviewWithdraw(amountPosition: string, instParams: InstParams) {
    const amountTokens = ethers.utils.parseUnits(amountPosition, 6)
    const paramsArray = instParamsContructor(instParams)

    let [usdcDue, instAddress, longAddress, shortAddress, positionSupply, settlementType]
        = await helperReader.getPreviewWithdraw(amountTokens, paramsArray)
    usdcDue = formatDecNumber(usdcDue)
    positionSupply = ethers.utils.formatUnits(positionSupply, 6)

    let size = 0;
    if (instAddress != ethers.constants.AddressZero) {
        const positionReader = new ethers.Contract(instAddress, INST_ABI, wagmiClient.provider)
        size = formatDecNumber(await positionReader.size())
    }

    return [usdcDue, instAddress, longAddress, shortAddress, positionSupply, settlementType, size]
}

export async function getSettlementTypeFromParams(instParams: InstParams) {
    const paramsArray = instParamsContructor(instParams)
    const settlementType = await helperReader.getSettlementTypeFromParams(paramsArray)
    return settlementType
}

export async function copyToast(msg: string) {
    const toastId = toast(msg,
        {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        })
}


export async function wrapTx(tx: Promise<any>) {
    const txReceipt = await tx;
    const toastId = toast("Your transaction is being processed. This may take several minutes.",
        {
            position: "top-right",
            autoClose: 120000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",

        })
    await wagmiClient.provider.waitForTransaction(txReceipt['hash'], 2, 100000)
    return [txReceipt, toastId];
}

export async function depositMint(instAddress: string, amountUsdc: string, instParams: InstParams) {
    const paramsArray = instParamsContructor(instParams)
    const amountCash = ethers.utils.parseUnits(amountUsdc, 6)
    const tx = factoryTransactor.depositMint(
        instAddress, amountCash, activeSigner._address, paramsArray
    )
    return tx
}

export async function redeem(instAddress: string, amountPosition: string, side: SIDE) {
    const amountTokens = ethers.utils.parseUnits(amountPosition, 6)
    const tx = side == SIDE.LONG ?
        factoryTransactor.redeemLong(instAddress, amountTokens, activeSigner._address, activeSigner._address) :
        factoryTransactor.redeemShort(instAddress, amountTokens, activeSigner._address, activeSigner._address)

    return tx
}

export async function withdraw(instAddress: string, amountPosition: string) {
    const amountTokens = ethers.utils.parseUnits(amountPosition, 6)
    const tx = factoryTransactor.withdrawUnmint(instAddress, amountTokens, activeSigner._address)
    return tx
}

export async function settle(instAddress: string) {
    const tx = factoryTransactor.settle(instAddress)
    return tx
}

export async function usdcApprove() {
    const tx = usdcTransactor.approve(CONFIG.FACTORY_ADDRESS, ethers.constants.MaxUint256)
    return tx
}

async function getSymbol(address: string) {
    const token = new ethers.Contract(address, ERC20_ABI, wagmiClient.provider)
    return await token.symbol()
}

async function importToken(address: `0x${string}`) {
    const symbol = await getSymbol(address)

    window.ethereum?.request({
        method: 'wallet_watchAsset',
        params: {
            type: 'ERC20',
            options: {
                address: address,
                symbol: `${symbol.slice(0, 3)}...${symbol.slice(-5)}`,
                decimals: 6,
            }
        }
    });

}

export async function importTokens(longAddress: `0x${string}`, shortAddress: `0x${string}`) {
    await importToken(longAddress)
    await importToken(shortAddress)
}

ethereumClient.watchAccount(async () => {
    await refreshWallet()
})

// ethereumClient.watchAccount(async() => {
//     console.log(ethereumClient.chains)
// })

ethereumClient.watchNetwork(async (r) => {
    const id = r['chain']!!['id']
    if (id != polygonMumbai.id) {
        const toastId = toast(
            "You need to be on the Mumbai Network to work with this dApp.",
            {
                position: "bottom-center",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            })
    }
})
import React from 'react';
import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap'

const banner_key = '5';

const Banner = () => {
  const [showBanner, setShowBanner] = useState(false);
  useEffect(() => {
    const savedData = window.localStorage.getItem(banner_key);
    if ( savedData !== null ) { setShowBanner(JSON.parse(savedData)) }
    else { setShowBanner(true) }
  }, []);
  // useEffect(() => {
  //   window.localStorage.setItem(banner_key, JSON.stringify(showBanner));
  // }, [showBanner]);
  if (showBanner) {
    return (
      <Container fluid style={{ fontFamily: 'Montserrat' }}>
        <div className='banner'>
          <div className='text-banner'>
            {/*<p><strong>Announcement:</strong>*/}
            <p><strong>Welcome to the Volorca pre-alpha test app!</strong>
              <p>
                If this is your first visit, please visit <a href="https://docs.volorca.com/quickstart/pre_alpha_setup/"
                                                             target="_blank" rel="noopener noreferrer">this page</a> to get set up.
              </p>
            </p>
          </div>
          <div className='buttondiv'>
            <button onClick={() => {
              setShowBanner(false)
              window.localStorage.setItem(banner_key, JSON.stringify(false));
            }}>Ok, don't show again.</button>
          </div>
        </div>
      </Container>
    )
  }
  return (

    <div>
      {

      }

    </div>
  )
}

export default Banner
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Mint from './Components/Mint';
import Redeem from './Components/Redeem';
import { HashRouter, Route, Routes } from "react-router-dom";
import {ethereumClient, wagmiClient, WALLETCONNECT_PROJ_ID} from "./helper_functions/connection";
import {Web3Modal} from "@web3modal/react";
import { WagmiConfig } from "wagmi";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import SettleLiquidate from "./Components/SettleLiquidate";
import MyPositions from "./Components/MyPositions";
import Withdraw from "./Components/Withdraw";
import {polygonMumbai} from "wagmi/chains";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement);
root.render(

  <HashRouter basename="/">
      <ToastContainer />
      <WagmiConfig client={wagmiClient}>
      <Routes>
      <Route path="/" element={<App />} />
      <Route path="/mint" element={<Mint />} />
      <Route path="/myPositions" element={<MyPositions />} />
      <Route path="/redeem" element={<Redeem />} />
      <Route path="/settleLiquidate" element={<SettleLiquidate />} />
      <Route path="/withdraw" element={<Withdraw />} />
    </Routes>
      </WagmiConfig>

      <Web3Modal
          projectId={WALLETCONNECT_PROJ_ID}
          ethereumClient={ethereumClient}
          themeColor={"blackWhite"}
          defaultChain={polygonMumbai}
      />
  </HashRouter>

);



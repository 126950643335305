import PARAMS from "./configuration/configuration.json"

export const FACTORY_ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "instrumentImpl_",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "tokenImpl_",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "priceReferencePolicy_",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "cashTokenAddress_",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "settlementDelay_",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "previousAdminRole",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "newAdminRole",
                "type": "bytes32"
            }
        ],
        "name": "RoleAdminChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "RoleGranted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "RoleRevoked",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "ADMIN_ROLE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DEFAULT_ADMIN_ROLE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "EPOCH_TIMESTAMP",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "EPOCH_YEAR",
        "outputs": [
            {
                "internalType": "uint16",
                "name": "",
                "type": "uint16"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "LAST_LEAP_YEAR",
        "outputs": [
            {
                "internalType": "uint16",
                "name": "",
                "type": "uint16"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "SECONDS_PER_DAY",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "SECONDS_PER_NONLEAP_YEAR",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "aaveV3Pool",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "name",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "symbol",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "singleUnitRepresentation",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "assetAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "expiryInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "expiryEpoch",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "priceInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "priceEpoch",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "twapInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256[3]",
                        "name": "thresholdIntervals",
                        "type": "uint256[3]"
                    }
                ],
                "internalType": "struct VolorcaTypes.Underlying",
                "name": "underlying",
                "type": "tuple"
            }
        ],
        "name": "addUnderlying",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "addressMap",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "cashTokenAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "currentInstrumentCounter",
        "outputs": [
            {
                "internalType": "uint32",
                "name": "",
                "type": "uint32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "instrumentAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            },
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "underlyingAsset",
                        "type": "address"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "strikePrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint16",
                        "name": "year",
                        "type": "uint16"
                    },
                    {
                        "internalType": "uint8",
                        "name": "month",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "day",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint32",
                        "name": "counter",
                        "type": "uint32"
                    }
                ],
                "internalType": "struct VolorcaTypes.InstrumentParams",
                "name": "instrumentParams",
                "type": "tuple"
            }
        ],
        "name": "depositMint",
        "outputs": [
            {
                "internalType": "address",
                "name": "newInstrumentAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amountTokens",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "gatekeeper",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "underlyingAsset",
                        "type": "address"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "strikePrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint16",
                        "name": "year",
                        "type": "uint16"
                    },
                    {
                        "internalType": "uint8",
                        "name": "month",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "day",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint32",
                        "name": "counter",
                        "type": "uint32"
                    }
                ],
                "internalType": "struct VolorcaTypes.InstrumentParams",
                "name": "instrumentParams",
                "type": "tuple"
            }
        ],
        "name": "getActiveInstrumentAddressId",
        "outputs": [
            {
                "internalType": "address",
                "name": "instrumentAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "instrumentId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "counterInstrumentId",
                "type": "uint256"
            },
            {
                "internalType": "uint32",
                "name": "counter",
                "type": "uint32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint16",
                "name": "year",
                "type": "uint16"
            },
            {
                "internalType": "uint8",
                "name": "month",
                "type": "uint8"
            },
            {
                "internalType": "uint8",
                "name": "day",
                "type": "uint8"
            }
        ],
        "name": "getDateId",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "dateId",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum VolorcaTypes.Status",
                "name": "status",
                "type": "uint8"
            },
            {
                "internalType": "address",
                "name": "underlying",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "startIdx",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "endIdx",
                "type": "uint256"
            }
        ],
        "name": "getDatesForUnderlying",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "dateIds",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint16",
                "name": "year",
                "type": "uint16"
            },
            {
                "internalType": "uint8",
                "name": "month",
                "type": "uint8"
            },
            {
                "internalType": "uint8",
                "name": "day",
                "type": "uint8"
            }
        ],
        "name": "getDatestringFromDate",
        "outputs": [
            {
                "internalType": "string",
                "name": "datestring",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "underlyingAsset",
                        "type": "address"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "strikePrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint16",
                        "name": "year",
                        "type": "uint16"
                    },
                    {
                        "internalType": "uint8",
                        "name": "month",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "day",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint32",
                        "name": "counter",
                        "type": "uint32"
                    }
                ],
                "internalType": "struct VolorcaTypes.InstrumentParams",
                "name": "instrumentParams",
                "type": "tuple"
            }
        ],
        "name": "getInstrumentId",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "instrumentId",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256[]",
                "name": "instrumentIdList",
                "type": "uint256[]"
            }
        ],
        "name": "getInstrumentParamsListFromIdList",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "underlyingAsset",
                        "type": "address"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "strikePrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint16",
                        "name": "year",
                        "type": "uint16"
                    },
                    {
                        "internalType": "uint8",
                        "name": "month",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "day",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint32",
                        "name": "counter",
                        "type": "uint32"
                    }
                ],
                "internalType": "struct VolorcaTypes.InstrumentParams[]",
                "name": "instrumentParamsList",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum VolorcaTypes.Status",
                "name": "status",
                "type": "uint8"
            },
            {
                "internalType": "address",
                "name": "underlying",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "dateId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "startIdx",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "endIdx",
                "type": "uint256"
            }
        ],
        "name": "getInstrumentsForDatesUnderlying",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "strike",
                        "type": "uint256"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "address",
                        "name": "longToken",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "shortToken",
                        "type": "address"
                    },
                    {
                        "internalType": "uint32",
                        "name": "version",
                        "type": "uint32"
                    }
                ],
                "internalType": "struct VolorcaTypes.DashboardData[]",
                "name": "dashboardDataList",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "startIdx",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "endIdx",
                "type": "uint256"
            }
        ],
        "name": "getLivePositions",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "positionAddresses",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "startIdx",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "endIdx",
                "type": "uint256"
            }
        ],
        "name": "getRetiredPositions",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "positionAddresses",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            }
        ],
        "name": "getRoleAdmin",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "assetAddress",
                "type": "address"
            }
        ],
        "name": "getThresholdIntervals",
        "outputs": [
            {
                "internalType": "uint256[3]",
                "name": "thresholdIntervals",
                "type": "uint256[3]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint16",
                "name": "year",
                "type": "uint16"
            },
            {
                "internalType": "uint8",
                "name": "month",
                "type": "uint8"
            },
            {
                "internalType": "uint8",
                "name": "day",
                "type": "uint8"
            }
        ],
        "name": "getTimestampFromDate",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getUnderlyings",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "name",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "symbol",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "singleUnitRepresentation",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "assetAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "expiryInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "expiryEpoch",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "priceInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "priceEpoch",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "twapInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256[3]",
                        "name": "thresholdIntervals",
                        "type": "uint256[3]"
                    }
                ],
                "internalType": "struct VolorcaTypes.Underlying[]",
                "name": "underlyings",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "grantRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "hasRole",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "instrumentImpl",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "instrumentMap",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "instrumentParamsMap",
        "outputs": [
            {
                "internalType": "address",
                "name": "underlyingAsset",
                "type": "address"
            },
            {
                "internalType": "enum VolorcaTypes.ContractType",
                "name": "contractType",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "strikePrice",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "thresholdIndex",
                "type": "uint8"
            },
            {
                "internalType": "uint16",
                "name": "year",
                "type": "uint16"
            },
            {
                "internalType": "uint8",
                "name": "month",
                "type": "uint8"
            },
            {
                "internalType": "uint8",
                "name": "day",
                "type": "uint8"
            },
            {
                "internalType": "uint32",
                "name": "counter",
                "type": "uint32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "tokenAddressToCheck",
                "type": "address"
            }
        ],
        "name": "isLive",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "tokenAddressToCheck",
                "type": "address"
            }
        ],
        "name": "isRetired",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "underlying",
                "type": "address"
            }
        ],
        "name": "numberOfDates",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "numActiveDates",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "numSettledDates",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "numFullyRedeemedDates",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "numberOfLivePositions",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "numLivePositions",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "numberOfRetiredPositions",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "numRetiredPositions",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "instrumentAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            },
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "underlyingAsset",
                        "type": "address"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "strikePrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint16",
                        "name": "year",
                        "type": "uint16"
                    },
                    {
                        "internalType": "uint8",
                        "name": "month",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "day",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint32",
                        "name": "counter",
                        "type": "uint32"
                    }
                ],
                "internalType": "struct VolorcaTypes.InstrumentParams",
                "name": "instrumentParams",
                "type": "tuple"
            }
        ],
        "name": "previewDepositMint",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountTokens",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "instrumentAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amountLong",
                "type": "uint256"
            }
        ],
        "name": "previewRedeemLong",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "instrumentAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amountShort",
                "type": "uint256"
            }
        ],
        "name": "previewRedeemShort",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "instrumentAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amountTokens",
                "type": "uint256"
            }
        ],
        "name": "previewWithdrawUnmint",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "priceReferencePolicy",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "instrumentAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amountLong",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "redeemLong",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "instrumentAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amountLong",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "redeemShort",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "assetAddress",
                "type": "address"
            }
        ],
        "name": "removeUnderlying",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "renounceRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "revokeRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "instrumentAddress",
                "type": "address"
            }
        ],
        "name": "settle",
        "outputs": [
            {
                "internalType": "enum VolorcaTypes.SettlementType",
                "name": "settledType",
                "type": "uint8"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "settlementDelay",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "settlerFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "fixedFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "percentageFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "feeCap",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "shortsFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "fixedFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "percentageFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "feeCap",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "singleCashUnit",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenImpl",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "underlyingMap",
        "outputs": [
            {
                "internalType": "string",
                "name": "name",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "symbol",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "singleUnitRepresentation",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "assetAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "expiryInterval",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "expiryEpoch",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "priceInterval",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "priceEpoch",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "twapInterval",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newAaveV3Pool",
                "type": "address"
            }
        ],
        "name": "updateAaveV3Pool",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newGatekeeper",
                "type": "address"
            }
        ],
        "name": "updateGatekeeper",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newPolicyAddress",
                "type": "address"
            }
        ],
        "name": "updatePriceReferencePolicy",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "fixedFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "percentageFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "feeCap",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct VolorcaTypes.FeeParams",
                "name": "newFeeParams",
                "type": "tuple"
            }
        ],
        "name": "updateSettlerFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "fixedFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "percentageFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "feeCap",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct VolorcaTypes.FeeParams",
                "name": "newFeeParams",
                "type": "tuple"
            }
        ],
        "name": "updateShortsFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "fixedFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "percentageFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "feeCap",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct VolorcaTypes.FeeParams",
                "name": "newFeeParams",
                "type": "tuple"
            }
        ],
        "name": "updateWithdrawUnmintFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "expiryTime",
                "type": "uint256"
            },
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "name",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "symbol",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "singleUnitRepresentation",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "assetAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "expiryInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "expiryEpoch",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "priceInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "priceEpoch",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "twapInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256[3]",
                        "name": "thresholdIntervals",
                        "type": "uint256[3]"
                    }
                ],
                "internalType": "struct VolorcaTypes.Underlying",
                "name": "underlying",
                "type": "tuple"
            }
        ],
        "name": "validateExpiry",
        "outputs": [
            {
                "internalType": "bool",
                "name": "passed",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "strike",
                "type": "uint256"
            },
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "name",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "symbol",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "singleUnitRepresentation",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "assetAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "expiryInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "expiryEpoch",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "priceInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "priceEpoch",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "twapInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256[3]",
                        "name": "thresholdIntervals",
                        "type": "uint256[3]"
                    }
                ],
                "internalType": "struct VolorcaTypes.Underlying",
                "name": "underlying",
                "type": "tuple"
            }
        ],
        "name": "validateStrike",
        "outputs": [
            {
                "internalType": "bool",
                "name": "passed",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "instrumentAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amountTokens",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            }
        ],
        "name": "withdrawUnmint",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawUnmintFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "fixedFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "percentageFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "feeCap",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
] as const
export const HELPER_ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "factory_",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "previousAdminRole",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "newAdminRole",
                "type": "bytes32"
            }
        ],
        "name": "RoleAdminChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "RoleGranted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "RoleRevoked",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "DEFAULT_ADMIN_ROLE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "factory",
        "outputs": [
            {
                "internalType": "contract VolorcaFactory",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "fees",
        "outputs": [
            {
                "internalType": "uint24",
                "name": "",
                "type": "uint24"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "addresses",
                "type": "address[]"
            },
            {
                "internalType": "bytes[]",
                "name": "data",
                "type": "bytes[]"
            }
        ],
        "name": "genericCall",
        "outputs": [
            {
                "internalType": "bytes[]",
                "name": "",
                "type": "bytes[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum VolorcaTypes.Status",
                "name": "status",
                "type": "uint8"
            },
            {
                "internalType": "address",
                "name": "underlying",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "startIdx",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "endIdx",
                "type": "uint256"
            }
        ],
        "name": "getDatesForUnderlying",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "dateIds",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum VolorcaTypes.Status",
                "name": "status",
                "type": "uint8"
            },
            {
                "internalType": "address",
                "name": "underlying",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "dateId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "startIdx",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "endIdx",
                "type": "uint256"
            }
        ],
        "name": "getInstrumentsForDatesUnderlying",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "strike",
                        "type": "uint256"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "address",
                        "name": "longToken",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "shortToken",
                        "type": "address"
                    },
                    {
                        "internalType": "uint32",
                        "name": "version",
                        "type": "uint32"
                    }
                ],
                "internalType": "struct VolorcaTypes.DashboardData[]",
                "name": "dashboardDataList",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "underlyingAsset",
                        "type": "address"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "strikePrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint16",
                        "name": "year",
                        "type": "uint16"
                    },
                    {
                        "internalType": "uint8",
                        "name": "month",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "day",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint32",
                        "name": "counter",
                        "type": "uint32"
                    }
                ],
                "internalType": "struct VolorcaTypes.InstrumentParams",
                "name": "instParams",
                "type": "tuple"
            },
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "getPosBalancesFromParams",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "longBalance",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "shortBalance",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "startIdx",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "endIdx",
                "type": "uint256"
            }
        ],
        "name": "getPositionBalances",
        "outputs": [
            {
                "internalType": "string[]",
                "name": "symbols",
                "type": "string[]"
            },
            {
                "internalType": "address[]",
                "name": "addresses",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "balances",
                "type": "uint256[]"
            },
            {
                "internalType": "int256[]",
                "name": "redeemable",
                "type": "int256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amountUsdc",
                "type": "uint256"
            },
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "underlyingAsset",
                        "type": "address"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "strikePrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint16",
                        "name": "year",
                        "type": "uint16"
                    },
                    {
                        "internalType": "uint8",
                        "name": "month",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "day",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint32",
                        "name": "counter",
                        "type": "uint32"
                    }
                ],
                "internalType": "struct VolorcaTypes.InstrumentParams",
                "name": "instParams",
                "type": "tuple"
            }
        ],
        "name": "getPreviewDeposit",
        "outputs": [
            {
                "internalType": "uint32",
                "name": "counter",
                "type": "uint32"
            },
            {
                "internalType": "uint256",
                "name": "amountTokens",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "instAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "longAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "shortAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "positionSupply",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "settlementType",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amountPosition",
                "type": "uint256"
            },
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "underlyingAsset",
                        "type": "address"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "strikePrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint16",
                        "name": "year",
                        "type": "uint16"
                    },
                    {
                        "internalType": "uint8",
                        "name": "month",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "day",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint32",
                        "name": "counter",
                        "type": "uint32"
                    }
                ],
                "internalType": "struct VolorcaTypes.InstrumentParams",
                "name": "instParams",
                "type": "tuple"
            }
        ],
        "name": "getPreviewRedeem",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "longUsdcDue",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "shortUsdcDue",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "instAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "longAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "shortAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "positionSupply",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "settlementType",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amountPosition",
                "type": "uint256"
            },
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "underlyingAsset",
                        "type": "address"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "strikePrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint16",
                        "name": "year",
                        "type": "uint16"
                    },
                    {
                        "internalType": "uint8",
                        "name": "month",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "day",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint32",
                        "name": "counter",
                        "type": "uint32"
                    }
                ],
                "internalType": "struct VolorcaTypes.InstrumentParams",
                "name": "instParams",
                "type": "tuple"
            }
        ],
        "name": "getPreviewWithdraw",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "usdcDue",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "instAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "longAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "shortAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "positionSupply",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "settlementType",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "underlying",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "dateId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "startIdx",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "endIdx",
                "type": "uint256"
            }
        ],
        "name": "getPrices",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "strike",
                        "type": "uint256"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "address",
                        "name": "longToken",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "shortToken",
                        "type": "address"
                    },
                    {
                        "internalType": "uint32",
                        "name": "version",
                        "type": "uint32"
                    },
                    {
                        "internalType": "uint256",
                        "name": "positionSupply",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "longPrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "shortPrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "string",
                        "name": "longSymbol",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "shortSymbol",
                        "type": "string"
                    },
                    {
                        "internalType": "enum VolorcaTypes.SettlementType",
                        "name": "settlementType",
                        "type": "uint8"
                    }
                ],
                "internalType": "struct VolorcaTypes.DashboardDataWithPrices[]",
                "name": "prices",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            }
        ],
        "name": "getRoleAdmin",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "underlyingAsset",
                        "type": "address"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "strikePrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint8",
                        "name": "thresholdIndex",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint16",
                        "name": "year",
                        "type": "uint16"
                    },
                    {
                        "internalType": "uint8",
                        "name": "month",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint8",
                        "name": "day",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint32",
                        "name": "counter",
                        "type": "uint32"
                    }
                ],
                "internalType": "struct VolorcaTypes.InstrumentParams",
                "name": "instParams",
                "type": "tuple"
            }
        ],
        "name": "getSettlementTypeFromParams",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "settlementType",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getUnderlyings",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "name",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "symbol",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "singleUnitRepresentation",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "assetAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "expiryInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "expiryEpoch",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "priceInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "priceEpoch",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "twapInterval",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256[3]",
                        "name": "thresholdIntervals",
                        "type": "uint256[3]"
                    }
                ],
                "internalType": "struct VolorcaTypes.Underlying[]",
                "name": "underlyings",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "grantRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "hasRole",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "oracleFeed",
        "outputs": [
            {
                "internalType": "contract IOracleFeed",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "renounceRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "revokeRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "secondsAgoSetting",
        "outputs": [
            {
                "internalType": "uint32",
                "name": "",
                "type": "uint32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "uniswapFactory",
        "outputs": [
            {
                "internalType": "contract IUniswapV3Factory",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "usdcAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
] as const
export const PRICE_REFERENCE_ABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "btcAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "ethAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "settlementDelay",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "baseAsset",
                "type": "address"
            }
        ],
        "name": "getCurrentPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "expiryTime",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "settlementDelay",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "baseAsset",
                "type": "address"
            }
        ],
        "name": "getExpirationPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maticAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "poolMap",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "uniswapFactory",
        "outputs": [
            {
                "internalType": "contract IUniswapV3Factory",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "usdcAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
] as const
export const ERC20_ABI = [
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "name_",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "symbol_",
                "type": "string"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "subtractedValue",
                "type": "uint256"
            }
        ],
        "name": "decreaseAllowance",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "addedValue",
                "type": "uint256"
            }
        ],
        "name": "increaseAllowance",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    }
] as const
export const INST_ABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint8",
                "name": "version",
                "type": "uint8"
            }
        ],
        "name": "Initialized",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "previousAdminRole",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "newAdminRole",
                "type": "bytes32"
            }
        ],
        "name": "RoleAdminChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "RoleGranted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "RoleRevoked",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "DEFAULT_ADMIN_ROLE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "MANAGER_ROLE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "aaveV3Pool",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "fixedFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "percentageFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "feeCap",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct VolorcaTypes.FeeParams",
                "name": "feeParams",
                "type": "tuple"
            },
            {
                "internalType": "uint256",
                "name": "totalAmount",
                "type": "uint256"
            }
        ],
        "name": "calculateFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "fee",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "contractParams",
        "outputs": [
            {
                "internalType": "address",
                "name": "priceReferencePolicy",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "underlyingName",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "assetToken",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "cashToken",
                "type": "address"
            },
            {
                "internalType": "enum VolorcaTypes.ContractType",
                "name": "contractType",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "expiryTime",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "settlementDelay",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "strikePrice",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "thresholdPrice",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            }
        ],
        "name": "depositMint",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountTokens",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getCurrentPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getExpirationPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            }
        ],
        "name": "getRoleAdmin",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "grantRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "hasRole",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "instrumentId",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "longToken",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "longsAwardedPool",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "name": "previewDepositMint",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountTokens",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amountLong",
                "type": "uint256"
            }
        ],
        "name": "previewRedeemLong",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amountShort",
                "type": "uint256"
            }
        ],
        "name": "previewRedeemShort",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amountTokens",
                "type": "uint256"
            }
        ],
        "name": "previewWithdrawUnmint",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amountLong",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "redeemLong",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amountShort",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "redeemShort",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "renounceRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "revokeRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "router",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "settlerAddress",
                "type": "address"
            }
        ],
        "name": "settle",
        "outputs": [
            {
                "internalType": "enum VolorcaTypes.SettlementType",
                "name": "settledType",
                "type": "uint8"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "settlementType",
        "outputs": [
            {
                "internalType": "enum VolorcaTypes.SettlementType",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "settler",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "settlerFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "fixedFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "percentageFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "feeCap",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "routerAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "tokenImpl",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "instrumentId_",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "name",
                "type": "string"
            },
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "priceReferencePolicy",
                        "type": "address"
                    },
                    {
                        "internalType": "string",
                        "name": "underlyingName",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "assetToken",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "cashToken",
                        "type": "address"
                    },
                    {
                        "internalType": "enum VolorcaTypes.ContractType",
                        "name": "contractType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "expiryTime",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "settlementDelay",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "strikePrice",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "thresholdPrice",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct VolorcaTypes.ContractParams",
                "name": "parameters",
                "type": "tuple"
            },
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "fixedFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "percentageFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "feeCap",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct VolorcaTypes.FeeParams",
                "name": "settlerFee_",
                "type": "tuple"
            },
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "fixedFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "percentageFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "feeCap",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct VolorcaTypes.FeeParams",
                "name": "shortsFee_",
                "type": "tuple"
            },
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "fixedFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "percentageFee",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "feeCap",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct VolorcaTypes.FeeParams",
                "name": "withdrawUnmintFee_",
                "type": "tuple"
            },
            {
                "internalType": "address",
                "name": "aaveV3Pool_",
                "type": "address"
            }
        ],
        "name": "setup",
        "outputs": [
            {
                "internalType": "bool",
                "name": "success",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "shortToken",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "shortsAwardedPool",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "shortsFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "fixedFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "percentageFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "feeCap",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "singleTokenUnit",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "size",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "sweepRemainder",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "thresholdStrikeSpread",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalPoolTracker",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amountTokens",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            }
        ],
        "name": "withdrawUnmint",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountCash",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawUnmintAllocation",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawUnmintFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "fixedFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "percentageFee",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "feeCap",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
] as const

export const CONFIG = PARAMS.PARAMS

export enum Status { Active, Settled, FullyRedeemed}
import {
    EthereumClient,
    modalConnectors,
    walletConnectProvider,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";

import { configureChains, createClient } from "wagmi";
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import { polygonMumbai } from "wagmi/chains";

export const WALLETCONNECT_PROJ_ID = "803d2d6b3ba4b306acacb65cf0df4441"

// // Wagmi client
// const { provider } = configureChains(chains, [
//     walletConnectProvider({ projectId: "<YOUR_PROJECT_ID>" }),
// ]);

const STALL_TIMEOUT = 10;

const { chains, provider } = configureChains(
    [polygonMumbai],
    [
        jsonRpcProvider({
            priority: 0,
            rpc: () => ({
                http: "https://rpc.ankr.com/polygon_mumbai/96cce7a6fa5504e0580f56b71dd3ae91c87422421268c394b6054c813e5b7aa7",
            }),
            // stallTimeout: STALL_TIMEOUT,
        }),
        // jsonRpcProvider({
        //     priority: 0,
        //     rpc: () => ({
        //         http: "https://omniscient-attentive-spree.matic-testnet.quiknode.pro/6315d19b31d1d020551369da1a79e3a2f358bbf9",
        //     }),
        //     // stallTimeout: STALL_TIMEOUT,
        // }),
        alchemyProvider({
            apiKey: "P1m2TYY5waIzqB33hEAEj1QH8CcVbzuH",
            priority: 0,
            // stallTimeout: STALL_TIMEOUT,
        }),
        // jsonRpcProvider({
        //     priority: 1,
        //     rpc: () => ({
        //         http: "https://rpc.ankr.com/polygon_mumbai",
        //     }),
        //     // stallTimeout: STALL_TIMEOUT,
        // }),
        // jsonRpcProvider({
        //     priority: 1,
        //     rpc: () => ({
        //         http: "https://rpc-mumbai.maticvigil.com",
        //     }),
        //     // stallTimeout: STALL_TIMEOUT,
        // }),
        // jsonRpcProvider({
        //     priority: 1,
        //     rpc: () => ({
        //         http: "https://polygon-mumbai.blockpi.network/v1/rpc/public",
        //     }),
        //     // stallTimeout: STALL_TIMEOUT,
        // }),
        // publicProvider({
        //     priority: 2,
        //     // stallTimeout: STALL_TIMEOUT,
        // }),
    ],
    {
        stallTimeout: STALL_TIMEOUT,
    }
    // [
    // jsonRpcProvider({
    //     priority: 1,
    //     rpc: () => ({
    //         http: "http://127.0.0.1:<port#>",
    //     }),
    // })
// ]
)

export const wagmiClient = createClient({
    autoConnect: true,
    connectors: modalConnectors({ appName: "web3Modal", chains }),
    provider,
});

// Web3Modal Ethereum Client
export const ethereumClient = new EthereumClient(wagmiClient, chains);
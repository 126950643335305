import './mint-redeem.css'
import Navigation from './Navigation';
import Banner from './Banner';
import Chart from './Chart'
import {BigNumber, ethers} from 'ethers';
import {ABIS} from '../helper_functions/abis';
import React, {useEffect, useRef, useState} from 'react';
import {Container} from 'react-bootstrap';
import LoadingSpinner from './LoadingSpinner';
import DatePicker from "react-datepicker";
import ModalMint from "./ModalMint";

import {
    ADDR_ASSETS_MAP,
    ASSETS,
    ASSETS_ADDR_MAP,
    CALL_PUT_SELECTION, compareBnToNumString,
    ContractType,
    convertToBN,
    formatDecNumber,
    getCurrentPrice, getPreviewDeposit, getUsdcAllowance, getUsdcBalance,
    InstParams,
    MTH2DIGITS,
    STEP_SIZE, DP, RISK_VALS, RISK_IDX, activeSigner, MS_DAY,
    dateToUtc, depositMint, toLocaleComma, getPosBalancesFromParams
} from "../helper_functions/contractFunctions";

import "react-datepicker/dist/react-datepicker.css";
import {useLocation} from "react-router-dom";
import {ethereumClient} from "../helper_functions/connection";


function dateStringToNums(dateString: string) {
    const splitString = dateString.split(" ")
    const year: number = Number(splitString[2])
    const month: number = Number(MTH2DIGITS[splitString[1]])
    const day: number = Number(splitString[0])
    return [year, month, day]
}


function formatDefaultPrice(currentRefPrice: number, asset: string) {
    const step = STEP_SIZE[asset]
    const remainder = currentRefPrice % step
    const returnVal = currentRefPrice - remainder
    return Number(returnVal.toFixed(DP[asset]))

}

function validatedDepositInput(inputNum: string, userBal: number) {
    let validated: number
    try {
        validated = Number(inputNum)
        validated = Number(validated.toFixed(6))
        if (validated > userBal) {
            validated = -2
        } else if (validated < 0) {
            validated = -1
        }
    } catch {
        validated = -1
    }
    return isNaN(validated) ? -1 : validated
}

const Mint = (props: any) => {
    const location = useLocation();
    const passedData = location.state?.values;

    const [startDate, setStartDate] = useState(
        dateToUtc(
        ((new Date()).getUTCHours() < 8) ?
        new Date() : new Date(Date.now() + MS_DAY)
        )
    );
    const [endDate, setEndDate] = useState(
        dateToUtc(
            new Date(Date.now() + ( 10 * 366 * MS_DAY))
        )
    );
    const onChange = (dates: [any, any]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const [year, month, day] = passedData != undefined ?
        dateStringToNums(passedData.cyptDate) :
        [startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate()]
    const initialParams: InstParams = passedData != undefined ? {
        underlyingAsset: ASSETS_ADDR_MAP[passedData.cypt],
        year: year, month: month, day: day,
        contractType: passedData.cyptAction == CALL_PUT_SELECTION.CALL ? ContractType.Call: ContractType.Put,
        strikePrice: convertToBN(passedData.cyptStrike),
        thresholdIndex: RISK_IDX[passedData.cypt][Math.abs(passedData.rValuedata)],
        counter: 0
    } :
        {
            underlyingAsset: ASSETS_ADDR_MAP["BTC"],
            year: year, month: month, day: day,
            contractType: ContractType.Call,
            strikePrice: convertToBN("30000"),
            thresholdIndex: 0,
            counter: 0
        }
    const [instParams, setInstParams] = useState(initialParams)

    const paramsRef = useRef(instParams)
    paramsRef.current = instParams

    const [userBal, setUserBal] = useState(0)
    const [showPreview, setShowPreview] = useState(false)
    const [modalValues, setModalValues] = useState({})

    const [loading, setLoading] = useState(true);
    const [defaultCurrency, setDefaultCurrency] = useState('BTC');
    const [dates, setDates] = useState([]);
    const [currentRefPrice, setCurrentRefPrice] = useState(0);
    const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(instParams.year, instParams.month - 1, instParams.day));
    const [rvalue, setRvalue] = useState([]);
    const [tableData, setTableData] = useState({});
    const [spinnerLoading, setSpinnerLoading] = useState(false);

    const [strikeInput, setStrikeInput] = useState(formatDecNumber(instParams.strikePrice))
    const [depositInput, setDepositInput] = useState(0)
    const [depositField, setDepositField] = useState("")
    const [depositError, setDepositError] = useState(0)

    ethereumClient.watchAccount(() => {
        getUsdcBalance().then(res => setUserBal(res))
    })

    interface TimeRemainingObject {
        days: number, hours: number, minutes: number
    }

    const updateUsdcBalance = async () => {
        { setSpinnerLoading(true) }
        const res = await getUsdcBalance()
        setUserBal(res)
        { setSpinnerLoading(false) }
    }

    const changeAsset = async (cryptCurrencyName: string, showSpinner=true) => {
        if (showSpinner) { setSpinnerLoading(true) }
        await Promise.all([
            getCurrentPrice(cryptCurrencyName),
        ]).then(
            async results => {
                const refPrice = formatDecNumber(results[0])
                const formattedPrice = formatDefaultPrice(refPrice, cryptCurrencyName)
                setCurrentRefPrice(refPrice)
                    setInstParams((prev) => ({...prev,
                        strikePrice: convertToBN(formattedPrice)
                    }))
                setStrikeInput(formattedPrice)
            })
        if (showSpinner) { setSpinnerLoading(false) }
    }

    const preparePreviewInfo = async () => {
        setSpinnerLoading(true)
        await Promise.all([
            getPreviewDeposit(depositInput.toString(), instParams),
            getUsdcAllowance()
        ]).then((res) => {
            const [counter, amt, instAddress, longAddress, shortAddress, positionSupply] = res[0]
            const userAllowance = res[1]
            const amountTokens = (amt as number) == 0 ? depositInput: (amt as number)
            setModalValues({
                instParams, depositInput, userBal, userAllowance, counter, amountTokens,
                instAddress, longAddress, shortAddress, positionSupply
            })
        })
        setShowPreview(true)
        setSpinnerLoading(false)
    }


    const setFilterData = async (cryptCurrencyName: any, showSpinner=true) => {
        if (showSpinner) { setSpinnerLoading(true) }

        await Promise.all([
            getCurrentPrice(cryptCurrencyName),
        ])
            .then(
                async results => {
                    const refPrice = formatDecNumber(results[0])
                    setCurrentRefPrice(refPrice)
                    // if (cryptCurrencyName) {
                    //     cryptoDate = cryptCurrencyName
                    // }
                    // setDates(results[0])
                    // let btcDates = results[0]
                    // var dateIndex = 0;
                    // if (dateIndexValue) {
                    //     for (var i = 0; i < btcDates.length; i++) {
                    //         if (btcDates[i] == dateIndexValue) {
                    //             dateIndex = i;
                    //         }
                    //     }
                    // }
                    // let ethRValues = results[1].map((x: BigNumber) => x.toNumber())
                    // let btcRange = results[2].map((x: BigNumber) => x.toNumber())
                    // const refPrice = formatDecNumber(results[3])
                    // // const maxAmount = formatDecNumber(results[4])
                    // // const timeRemaining = getTimeRemaining(btcDates[dateIndex])
                    // const btcStrikeWithObjects = await getStrikeWithObjects(cryptCurrencyName, btcDates[dateIndex])
                    // const oneTablePage = formatStrikeWithObjects(btcStrikeWithObjects[0], btcStrikeWithObjects[1])
                    // setCurrentRefPrice(refPrice)
                    // // setTimeRemaining(timeRemaining as any)
                    // setRvalue(ethRValues)
                    // setTableData(oneTablePage)
                    // setTableValue(cryptCurrencyName)
                    // setCurrentDate(btcDates[dateIndex])
                    // setCurrentCurrencyName(cryptCurrencyName)
                }
            )
        if (showSpinner) { setSpinnerLoading(false) }

    }
    const crptname = ADDR_ASSETS_MAP[instParams.underlyingAsset]
    useEffect(
        () => {
            let timer1 = setTimeout(() => setLoading(true), 1000);
            return () => {
                setFilterData(crptname)
                clearTimeout(timer1);

            };
        }, []);

    useEffect(() => {
        setFilterData(crptname)
        getUsdcBalance().then(res => setUserBal(res))
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            const latestParams = paramsRef.current
            setTimeRemaining(getTimeRemaining(latestParams.year, latestParams.month - 1, latestParams.day))
            setFilterData(ADDR_ASSETS_MAP[latestParams.underlyingAsset], false)
            getUsdcBalance().then(res => setUserBal(res))
        }, 5000);
        return () => clearInterval(interval);
    }, []);


    function getTimeRemaining(year: number, month: number, day: number) {
        const end = new Date(year, month,day,0,0,0,0)
        end.setUTCFullYear(year, month, day)
        end.setUTCHours(8, 0, 0, 0)
        const start = new Date()

        const diff = end.getTime() - start.getTime()
        const days = Math.floor(diff / 86400000 )
        const hours = Math.floor((diff % 86400000) / 3600000 )
        const mins = Math.floor((diff % 3600000) / 60000 )
        const timeRemaining: TimeRemainingObject = {
            days: days, hours: hours, minutes: mins
        }
        return timeRemaining
    }


        return (
            <div style={{ fontFamily: 'Montserrat' }}>
                <Navigation />
                <Banner />

                <Container fluid>
                    <div className='header'>
                        <div className='dropdown'>

                            <select value={props.cypt} className="form-select form-select-md" aria-label=".form-select-md example" name='setFilterData'
                                    onChange={(e) =>
                                    {
                                        setInstParams((prev) => ({...prev,
                                            underlyingAsset: ASSETS_ADDR_MAP[e.target.value],
                                        }))

                                        changeAsset(e.target.value).then(() => {})
                                    }
                                    } >
                                <option selected={ADDR_ASSETS_MAP[instParams.underlyingAsset]==ASSETS.BTC}
                                        value={ASSETS.BTC}>{ASSETS.BTC}</option>
                                <option selected={ADDR_ASSETS_MAP[instParams.underlyingAsset]==ASSETS.ETH}
                                        value={ASSETS.ETH}>{ASSETS.ETH}</option>
                                <option selected={ADDR_ASSETS_MAP[instParams.underlyingAsset]==ASSETS.MATIC}
                                        value={ASSETS.MATIC}>{ASSETS.MATIC}</option>
                            </select>
                        </div>
                        <div className='dropdown'>

                            <DatePicker selected={new Date(instParams.year, instParams.month - 1, instParams.day)}
                                        onChange={(date) => {

                                            if (date != null) {
                                                setInstParams((prev) => ({...prev,
                                                    year: date.getFullYear(),
                                                    month: date.getMonth() + 1,
                                                    day: date.getDate()
                                                }))
                                                setTimeRemaining( getTimeRemaining(
                                                        date.getFullYear(), date.getMonth() , date.getDate()
                                                    ))
                                            }
                                        }}
                                        dateFormat="dd MMM yyyy"
                                        minDate={startDate}
                                        maxDate={endDate}
                                        />

                        </div>
                        <div className='header-part'>
                            <div className=''><b>Current Reference Price</b></div>
                            <div style={{fontSize:"larger"}}>{toLocaleComma(currentRefPrice, 6)} </div>
                        </div>
                        <div className='header-part'>
                            <div className=''><b>Time To Expiry</b></div>
                            <div>{timeRemaining.days} D {timeRemaining?.hours} H {timeRemaining?.minutes} M</div>
                        </div>
                        <div className='header-part'>
                            {/*<button className='ml-4 cnctwltbtn'><b>CONNECT WALLET</b></button>*/}
                        </div>
                    </div>

                    <div className='formsection'>
                        <div className="formsection-left">
                            <div className="frmsecleft">
                                <div className="frmsecleft-tx">
                                    <span className='textbg'>TYPE</span>
                                </div>
                                <div className="frmsecleft-drpdwn">
                                    <select className="form-select form-select-md" aria-label=".form-select-md example"
                                            onChange={(e) =>
                                                setInstParams((prev) => ({...prev,
                                                    contractType: e.target.value == CALL_PUT_SELECTION.CALL ?
                                                        ContractType.Call : ContractType.Put
                                                }))
                                            }>
                                        <option selected={instParams.contractType == ContractType.Call} value={CALL_PUT_SELECTION.CALL}>{CALL_PUT_SELECTION.CALL}</option>
                                        <option selected={instParams.contractType == ContractType.Put} value={CALL_PUT_SELECTION.PUT}>{CALL_PUT_SELECTION.PUT}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="frmsecleft">
                                <div className="frmsecleft-tx">
                                    <span className='textbg'>STRIKE</span>
                                </div>

                                <div className="frmsecleft-drpdwn">

                                    <input className='inp-field-strike' type='number' step={STEP_SIZE[ADDR_ASSETS_MAP[instParams.underlyingAsset]]}
                                           value={strikeInput}
                                           min={0}
                                           onChange={(e) => {setStrikeInput(Number(e.target.value))}}

                                           onBlurCapture={(e) => {
                                               const formatted = formatDefaultPrice(Number(e.target.value), ADDR_ASSETS_MAP[instParams.underlyingAsset])
                                               setInstParams((prev) => ({...prev,
                                                   strikePrice: convertToBN(formatted)
                                               }))
                                               setStrikeInput(formatted)
                                           }}>
                                    </input>
                                    <p className="fst-italic fs-6 text-end">
                                        Please enter a value in multiples of {STEP_SIZE[ADDR_ASSETS_MAP[instParams.underlyingAsset]]}.
                                        Use the up/down keys to snap to closest multiple.
                                    </p>
                                </div>
                            </div>
                            <div className="frmsecleft">
                                <div className="frmsecleft-tx">
                                    <span className='textbg'>RISK</span>
                                </div>
                                <div className="frmsecleft-drpdwn">
                                    <select
                                        className="form-select form-select-md"
                                        aria-label=".form-select-md example"
                                        onChange={(e) =>
                                            setInstParams((prev) => ({
                                                ...prev, thresholdIndex: Number(e.target.value)
                                            }))
                                        }>

                                        {/*<option selected value={instParams.thresholdIndex} hidden>{*/}
                                        {/*    `R${instParams.thresholdIndex + 1} (${instParams.contractType == ContractType.Call ?*/}
                                        {/*        `+${RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][instParams.thresholdIndex]}`*/}
                                        {/*        : `-${RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][instParams.thresholdIndex]}`*/}
                                        {/*    })`}</option>*/}
                                        <option selected={instParams.thresholdIndex == 0} value={0}>{
                                            `R${1} (${instParams.contractType == ContractType.Call ?
                                            `+${RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][0]}`
                                            : `-${RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][0]}`
                                        })`}</option>
                                        <option selected={instParams.thresholdIndex == 1} value={1}>{
                                            `R${2} (${instParams.contractType == ContractType.Call ?
                                                `+${RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][1]}`
                                                : `-${RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][1]}`
                                            })`}</option>
                                        <option selected={instParams.thresholdIndex == 2} value={2}>{
                                            `R${3} (${instParams.contractType == ContractType.Call ?
                                                `+${RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][2]}`
                                                : `-${RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][2]}`
                                            })`}</option>
                                    </select>
                                </div>
                            </div>
                            {/*<div className="frmsecleft mt-5">*/}

                            {/*    <div className="frmsecleft-tx">*/}
                            {/*        <span className="text-bg-2">Long Tokens <br />to Redeem</span>*/}
                            {/*    </div>*/}
                            {/*    <div className="formsection-right-sec-inp">*/}
                            {/*        <input className='inp-field-lngtoken' type='number' step={1000}*/}
                            {/*               onChange={(e) => { setNumberInput(e.target.value) }}>*/}
                            {/*        </input>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="frmsecleft">
                                <div className="frmsecleft-tx">
                                    <span className='text-sm'>USDC Deposit Amount</span>
                                </div>

                                <div className="frmsecleft-drpdwn">
                                    <input className='inp-field-usdc' type='text' inputMode="decimal"
                                           value={depositField}
                                        pattern="^\d*(\.\d{1,6})?$"
                                           onChange={(e) => {
                                               setDepositField(e.target.value)
                                                setDepositError(0)
                                           }}
                                           onBlur={(e) => {
                                               const validated = validatedDepositInput(e.target.value, userBal)
                                               setDepositInput((prev) => (validated >= 0 ? validated : prev))
                                               setDepositField((prev) => (validated >= 0? validated.toString() : depositInput.toString()))
                                               if (validated < 0) {setDepositError(validated)}
                                           }}

                                           // onChange={(e) => {setStrikeInput(Number(e.target.value))}}
                                           //
                                           // onBlurCapture={(e) => {
                                           //     const formatted = formatDefaultPrice(Number(e.target.value), ADDR_ASSETS_MAP[instParams.underlyingAsset])
                                           //     setInstParams((prev) => ({...prev,
                                           //         strikePrice: convertToBN(formatted)
                                           //     }))
                                           //     setStrikeInput(formatted)
                                           // }}
                                    >
                                    </input>
                                    <div>
                                            <span className="inp-comment-usdc-max" onClick={() => {
                                                updateUsdcBalance().then(() => {})
                                                setDepositInput(0)
                                                setDepositField("0")
                                            }}>
                                        (refresh)
                                    </span>
                                    <span className="inp-comment-usdc">
                                        Balance: {userBal} USDC
                                    </span>
                                    <span className="inp-comment-usdc-max" onClick={() => {
                                        setDepositInput(userBal)
                                        setDepositField(userBal.toString())
                                    }}>
                                        max
                                    </span>
                                    </div>
                                    <span className="inp-comment-usdc-error">
                                        {
                                            depositError == -1 ? `Amount must be at least 0 and up to 6 decimal places.`:
                                        depositError == -2 ? `Cannot deposit more than your USDC balance.` : ``
                                        }
                                    </span>
                                </div>
                            </div>

                            {/*<div className="frmsecleft">*/}
                            {/*    <div className="frmsecleft-dt">*/}
                            {/*        <div className="frmsecleft-dt-1 fw-light">*/}
                            {/*            You will Need to deposit*/}
                            {/*            <span className='h6'> 14,400 USDC </span>*/}
                            {/*        </div>*/}
                            {/*        <div className="frmsecleft-dt-2 fw-light">*/}
                            {/*            You will receieve <br />*/}
                            {/*            <span>{numberInput}<br /></span>*/}
                            {/*            <span>{currentCurrencyName} {currentDate} {actionData} {strikeValue} {riskValue}</span>*/}
                            {/*        </div>*/}
                            {/*        <div className="frmsecleft-dt-3 fw-light">*/}

                            {/*            <span>{ADDR_ASSETS_MAP[instParams.underlyingAsset]}</span> ||*/}
                            {/*            <span>{instParams.contractType}</span> ||*/}
                            {/*            <span>{instParams.year} {instParams.month} { instParams.day}</span> ||*/}
                            {/*            <span>{ethers.utils.formatUnits(instParams.strikePrice, 6)}</span> ||*/}
                            {/*            <span>{instParams.thresholdIndex}</span> ||*/}
                            {/*            <span>{depositInput}</span>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="frmsecleft">
                                <div className="frmsecleft-btn-div-frm2">
                                    {activeSigner == undefined?
                                       <div>
                                           Please connect wallet to continue.
                                       </div>
                                        :
                                        <button className="" type='button'
                                                onClick={() => {
                                                    preparePreviewInfo()
                                                }}>
                                            Preview MINT
                                        </button>
                                    }
                                </div>

                            </div>

                        </div>

                        {/*<div className="formsection-right-chart">*/}
                        {/*    <Chart tableValue={tableValue} riskValue={riskValue} strikeValue={strikeValue} currentRefPrice={currentRefPrice} />*/}
                        {/*</div>*/}
                    </div>
                </Container >
                <ModalMint show={showPreview} handleClose={() => setShowPreview(false)} modalValues={modalValues}/>

                {spinnerLoading ? <LoadingSpinner /> : null}
            </div >
        )


}

export default Mint;
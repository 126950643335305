import React, { Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import LoadingSpinner from './Components/LoadingSpinner';
import LoadingSpinner from "./LoadingSpinner";

const Navigation = React.lazy(() => import('./Navigation'));
const Banner = React.lazy(() => import('./Banner'));
const PositionsTable = React.lazy(() => import('./PositionsTable'));
function MyPositions() {
    return (
        <>
            <div>
                <div>
                    <Suspense fallback={<LoadingSpinner />}>
                        <Navigation />
                        <Banner />
                        <PositionsTable />
                    </Suspense>
                </div>
            </div>

        </>
    );
}

export default MyPositions;
import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import {Row, Col, Container} from "react-bootstrap";

import {
    activeSigner,
    ADDR_ASSETS_MAP,
    CALL_PUT_SELECTION,
    compareBnToNumString,
    depositMint,
    EXPLORER_TOKEN_URL,
    EXPLORER_URL,
    formatDecNumber,
    getPreviewDeposit,
    getPositionBalance,
    getUsdcAllowance,
    importTokens,
    RISK_VALS,
    usdcApprove,
    wrapTx,
    ZERO_ADDRESS,
    settle,
    getPreviewSettle,
    getPreviewFull,
    ContractType,
    TimeRemainingObject,
    SETTLEMENT_TYPE,
    InstParams, PositionInfo, SIDE, convertToBN, toLocaleComma
} from "../helper_functions/contractFunctions";
import {BigNumber, ethers} from "ethers";
import {Link} from "react-router-dom";

const ModalSettle = (props: any) => {
let [instParams, longUsdcDue, shortUsdcDue, instAddress, longAddress, shortAddress, positionSupply, settlementType,
    currentRefPrice, size] =
    [
        props.modalValues.instParams,
        props.modalValues.longUsdcDue,
        props.modalValues.shortUsdcDue,
        props.modalValues.instAddress,
        props.modalValues.longAddress,
        props.modalValues.shortAddress,
        props.modalValues.positionSupply,
        props.modalValues.settlementType,
        props.modalValues.currentRefPrice,
        props.modalValues.size
    ]

    const [depositDisabled, setDepositDisabled] = useState(false)
    const [newAllowance, setNewAllowance] = useState(BigNumber.from(0))

    const [depositCompleted, setDepositCompleted] = useState(false)
    const [newInstAddresses, setNewInstAddresses] = useState(["", "", ""])
    const [newPosBal, setNewPosBal] = useState(0)
    const [newSettlementType, setNewSettlementType] = useState(settlementType)
    const [longBal, setLongBal] = useState(0)
    const [shortBal, setShortBal] = useState(0)



    const settleInstrument = async () => {
        setDepositDisabled(true)
        settle(instAddress).then(
            (tx) => { wrapTx(tx).then(([txReceipt, toastId]) => {
                console.log("settled")
                getPreviewFull("1", instParams).then(
                    ([newCounter, _, newAddress, newLong, newShort, __, newSettlementType, newSize]) => {
                        toast.update(toastId,{
                            render: "Settlement/Liquidation complete.",
                            type: toast.TYPE.SUCCESS,
                            isLoading: false,
                            autoClose: 3000,
                            hideProgressBar: true
                        })
                        setDepositCompleted(true)
                        setNewInstAddresses([newAddress, newLong, newShort])
                        setNewSettlementType(newSettlementType)
                        getPositionBalance(newLong).then((newLongBal) => {setLongBal(newLongBal)})
                        getPositionBalance(newShort).then((newShortBal) => {setShortBal(newShortBal)})
                        console.log("done")
                    }
                )
            })}
        )
    }

    function isExpired(year: number, month: number, day: number) {
        const expiry = new Date()
        expiry.setUTCFullYear(year, month, day)
        expiry.setUTCHours(8, 0, 0, 0)
        const current = new Date()

        const diff = expiry.getTime() - current.getTime()

        return diff <= 0

    }

    function getThresholdPrice() {
        const spread = RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][instParams.thresholdIndex]
        const strike = formatDecNumber(instParams.strikePrice)

        const thresholdPrice = instParams.contractType == ContractType.Call ?
            strike + spread : strike - spread
        return thresholdPrice
    }

    function getPositionInfo(side: SIDE) {
        const posInfo: PositionInfo = {
            instParams: instParams,
            side: side,
            amount: side == SIDE.LONG ? longBal: shortBal,
            address: side == SIDE.LONG ? props.modalValues.longAddress : props.modalValues.shortAddress,
            thresholdPrice: convertToBN(getThresholdPrice()),
            assetName: ADDR_ASSETS_MAP[instParams.underlyingAsset],
        }
        return posInfo
    }



  if (instParams != undefined) {
      return (

          <div className='Uniswap'>

              <Modal style={{ fontFamily: 'Montserrat' }}
                     size="xl" show={props.show} onHide={() => {
                  props.handleClose()

              }}
                     onExited={() => {
                         setDepositDisabled(false)
                         setDepositCompleted(false)
                     }}
              >
                  <Modal.Header style={{'backgroundColor': "#6c7d6d", 'color': 'white'}}>
                      <Container>
                      <div style={{padding: "0.2rem"}}>
                          <Row className="flex-wrap">
                          <div style={{'textAlign': "left"}}>
                              <h2>Settlement Preview</h2><br/>
                          </div>
                          </Row>
                          <Row className="flex-wrap">
                              <Col className="modal-col"><span className='h4'>Asset: {ADDR_ASSETS_MAP[instParams.underlyingAsset]} </span></Col>
                              <Col className="modal-col"><span className='h4'>Type: {instParams.contractType == 0 ? CALL_PUT_SELECTION.CALL : CALL_PUT_SELECTION.PUT} </span></Col>
                              <Col className="modal-col"><span className='h4'>Strike: {toLocaleComma(formatDecNumber(instParams.strikePrice),2)} </span></Col>
                              <Col className="modal-col"><span className='h4'>Expiry: {(new Date(`${instParams.year}-${instParams.month}-${instParams.day}`)).toDateString()
                                  .split(' ').slice(1).join(' ')} </span></Col>
                          </Row>
                          <Row className="flex-wrap modal-row">
                              <Col className={"modal-col"}><span className='h6'>Risk: {`R${instParams.thresholdIndex + 1}`}</span></Col>
                              <Col className={"modal-col"}><span className='h6'>Threshold: {toLocaleComma(getThresholdPrice(), 2)}</span></Col>
                              <Col className={"modal-col"}><span className='h6'>Version: {instParams.counter}</span></Col>
                              <Col className={"modal-col"}><span className='h6'>Minted Supply: {toLocaleComma(positionSupply, 2)}</span></Col>
                              {/*<Col className={"modal-col"}><span className='h6'>Locked: {toLocaleComma(size, 2)} USDC</span></Col>*/}
                          </Row>

                          <Row className={"flex-wrap modal-row"}>
                        <Col className={"modal-col"}>
                      <span className='h5' >
                          {activeSigner != undefined ?
                              `From: ${activeSigner._address.slice(0, 7)}..${activeSigner._address.slice(-5)}`
                              :
                              `Please connect wallet to continue.`} </span>
                        </Col>
                          </Row>
                      </div>
                      </Container>
                  </Modal.Header>
                  <Modal.Body style={{
                      'backgroundColor': "#6c7d6d",
                      'display': 'flex',
                      'alignItems': 'center',
                      'justifyContent': 'center',
                      'color': 'white',
                      'flexDirection': 'column'
                  }}>
                      <Container>
                      {depositCompleted ?
                          <Row className={"flex-wrap"}>
                              <Col className="modal-col-borderless ">
                                  <Button className="button-width" variant="dark" size="lg"
                                          onClick={() => {
                                              props.handleClose()
                                          }}
                                  >Close</Button>
                              </Col>
                          </Row>
                          : <>
                              { (settlementType == SETTLEMENT_TYPE.ITM || settlementType == SETTLEMENT_TYPE.OTM ||
                                  settlementType == SETTLEMENT_TYPE.Liquidated) ?
                                  null
                                          :

                                  settlementType == SETTLEMENT_TYPE.NonExistent ?
                                      null
                                      :
                              (
                                      ((isExpired(instParams.year, instParams.month - 1, instParams.day)) ||
                                      ((instParams.contractType == ContractType.Call) && (currentRefPrice > getThresholdPrice())) ||
                                      ((instParams.contractType == ContractType.Put) && (currentRefPrice < getThresholdPrice()))
                                  ) ?
                                          <Row className={"flex-wrap"}>
                                                <Col className="modal-col-borderless">
                                          <Button className="button-width" variant="light" size="lg"
                                              disabled={depositDisabled}
                                              onClick={() => {
                                                  settleInstrument()
                                              }}
                                      >
                                          {isExpired(instParams.year, instParams.month - 1, instParams.day) ? `Settle`:`Liquidate`}
                                      </Button>
                                                </Col></Row>
                                          :
                                          <Row className={"flex-wrap"}>
                                              <Col className="modal-col-borderless">
                                          <Button className="button-width"variant="outline-light" size="lg"
                                                  disabled={true}
                                          >
                                             This instrument cannot be settled yet.
                                          </Button>
                                              </Col></Row>
                              )
                              }

                              {
                                  <Row className={"flex-wrap"}>
                                      <Col className="modal-col-borderless">
                                  <Button className="button-width" variant="dark" size="lg"
                                       onClick={() => {
                                           props.handleClose()
                                       }}
                              >Close</Button>
                                      </Col></Row>
                              }
                          </>
                      }


                      {depositCompleted ?
                          <>
                              {newSettlementType == SETTLEMENT_TYPE.OTM ?
                                  <>
                                  <Row className={"flex-wrap"}>
                                      <Col className="modal-col-borderless h6">
                                          Settlement Completed. This instrument has been settled
                                      <b> out-of-the-money</b>.
                                      </Col></Row>
                                      <Row className={"flex-wrap"}>
                                        <Col className="modal-col-borderless">
                                      <Link to='/redeem' state={{ params: getPositionInfo(SIDE.SHORT) }}
                                            style={{ 'color': 'white', marginLeft:"0rem" }}
                                      >
                                          <Button className="button-width" variant="light" size="lg">Redeem SHORT
                                          </Button>
                                      </Link>
                                      </Col></Row>
                                  </>
                                  :
                              newSettlementType == SETTLEMENT_TYPE.ITM ?
                                  <>
                                  <Row className={"flex-wrap"}>
                                      <Col className="modal-col-borderless h6">
                                          Settlement Completed. This instrument has been settled
                                  <b> in-the-money</b>.

                              </Col></Row>
                              <Row className={"flex-wrap"}>
                                  <Col className="modal-col-borderless">

                                  <Link to='/redeem' state={{ params: getPositionInfo(SIDE.LONG) }}
                                        style={{ 'color': 'white', marginLeft:"0rem" }}
                                  ><Button className="button-width" variant="light" size="lg">Redeem LONG</Button>
                                  </Link>
                                  </Col>
                                  <Col className="modal-col-borderless">
                                  <Link to='/redeem' state={{ params: getPositionInfo(SIDE.SHORT) }}
                                        style={{ 'color': 'white', marginLeft:"0rem" }}
                                  ><Button className="button-width" variant="light" size="lg">Redeem SHORT</Button>
                                  </Link>
                                      </Col></Row>
                                  </>
                                  :
                                  <>
                                  <Row className={"flex-wrap"}>
                                      <Col className="modal-col-borderless h6">
                                          Settlement Completed. This instrument has been settled with status
                                      <b> Liquidated</b>.
                                      </Col></Row>
                                      <Row className={"flex-wrap"}>
                                          <Col className="modal-col-borderless">
                                      <Link to='/redeem' state={{ params: getPositionInfo(SIDE.LONG) }}
                                            style={{ 'color': 'white', marginLeft:"0rem" }}
                                      ><Button className="button-width" variant="light" size="lg">Redeem LONG</Button>
                                      </Link>
                                      </Col></Row>
                                  </>

                              }
                              <Row className={"flex-wrap modal-row"}>
                                  <Col className={"modal-col-borderless-sm"}> <span className={"h6"}>
                                      View on explorer: </span>
                                  </Col>
                              </Row><Row className="flex-wrap">
                                  <Col className={"modal-col-borderless-sm"}><span className={"h6"}>
                                      <a href={`${EXPLORER_URL}${newInstAddresses[0]}`} target="_blank"
                                         style={{color: "lightblue"}}> Instrument
                                            </a>
                                  </span></Col>
                                  <Col className={"modal-col-borderless-sm"}><span className={"h6"}>
                                      <a href={`${EXPLORER_TOKEN_URL}${newInstAddresses[1]}`} target="_blank"
                                         style={{color: "lightblue"}}> Long Position
                                            </a>
                                  </span></Col>
                                  <Col className={"modal-col-borderless-sm"}><span className={"h6"}>
                                      <a href={`${EXPLORER_TOKEN_URL}${newInstAddresses[2]}`} target="_blank"
                                         style={{color: "lightblue"}}> Short Position
                                            </a>
                                  </span></Col>
                              </Row>

                              {/*<Row className={"flex-wrap"}>*/}
                              {/*    <Col className="modal-col-borderless">*/}
                              {/*    <Button className="button-width" variant="outline-light" size="sm" onClick={() => {*/}
                              {/*        importTokens(longAddress, shortAddress)*/}
                              {/*    }}>*/}
                              {/*        Import position tokens to Metamask assets list.*/}
                              {/*    </Button>*/}
                              {/*    </Col>*/}
                              {/*</Row>*/}
                          </>
                          :
                          settlementType == SETTLEMENT_TYPE.NonExistent ?
                              <Row className={"flex-wrap"}>
                                  <Col className="modal-col-borderless">
                                  <span className='h6'>This instrument has not been created.</span>
                                  </Col></Row>
                              : settlementType != SETTLEMENT_TYPE.NotSettled ?
                                  <Row className={"flex-wrap"}>
                                      <Col className="modal-col-borderless">
                                        <span className='h6'>This instrument has already been settled.</span>
                                      </Col>
                                  </Row>
                              :

                              <>
                                  <Row className={"flex-wrap"}>
                                      <Col className="modal-col-borderless">
                                        <span className={"h6"}> View on explorer: </span>
                                      </Col>
                                  </Row><Row className="flex-wrap">
                                      <Col className="modal-col-borderless">
                                          <span className={"h6"}>
                                              <a href={`${EXPLORER_URL}${instAddress}`} target="_blank"
                                                 style={{color: "lightblue"}}> Instrument
                                                    </a>
                                          </span>
                                      </Col>
                                      <Col className="modal-col-borderless">
                                          <span className={"h6"}>
                                              <a href={`${EXPLORER_TOKEN_URL}${longAddress}`} target="_blank"
                                                 style={{color: "lightblue"}}> Long Position
                                                    </a>
                                                      </span>
                                      </Col>
                                      <Col className="modal-col-borderless">
                                          <span className={"h6"}>
                                              <a href={`${EXPLORER_TOKEN_URL}${shortAddress}`} target="_blank"
                                                 style={{color: "lightblue"}}> Short Position
                                                </a>
                                          </span>
                                      </Col>
                                  </Row>

                                  {/*<Row className="flex-wrap">*/}
                                  {/*    <Col className="modal-col-borderless">*/}
                                  {/*        <Button className="button-width" variant="outline-light" size="sm" onClick={() => {*/}
                                  {/*            importTokens(longAddress, shortAddress)*/}
                                  {/*        }}>*/}
                                  {/*            Import position tokens to Metamask assets list*/}
                                  {/*        </Button>*/}
                                  {/*    </Col>*/}
                                  {/*</Row>*/}
                              </>
                      }
                      </Container>
                  </Modal.Body>
              </Modal>
          </div>
      )

  } else {
      return (
              <div className='Uniswap'>

                  <Modal size="xl" show={props.show} onHide={() => {
                      props.handleClose()

                  }}
                         onExited={() => {
                             setDepositDisabled(false)
                             setDepositCompleted(false)
                         }}
                  >
                      No parameters found.
                  </Modal>
              </div>
      )
  }
  return (
      <>
      </>
  )

}
export default ModalSettle;

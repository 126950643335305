import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import {Row, Col, Container} from "react-bootstrap";

import {
    activeSigner,
    ADDR_ASSETS_MAP,
    CALL_PUT_SELECTION, compareBnToNumString, depositMint, EXPLORER_TOKEN_URL, EXPLORER_URL,
    formatDecNumber, getPreviewDeposit, getPositionBalance, getUsdcAllowance, importTokens,
    RISK_VALS, usdcApprove, wrapTx,
    ZERO_ADDRESS, toLocaleComma
} from "../helper_functions/contractFunctions";
import {BigNumber, ethers} from "ethers";

const ModalMint = (props: any) => {
  // const instParams = props.modalValues.instParams
let [instParams, depositInput, userBal, userAllowance, counter, amountTokens,
  instAddress, longAddress, shortAddress, positionSupply] =
    [props.modalValues.instParams, props.modalValues.depositInput,
        props.modalValues.userBal,
      props.modalValues.userAllowance, props.modalValues.counter,
      props.modalValues.amountTokens, props.modalValues.instAddress,
      props.modalValues.longAddress, props.modalValues.shortAddress,
      props.modalValues.positionSupply]

    const [approveDisabled, setApproveDisabled] = useState(false)
    const [depositDisabled, setDepositDisabled] = useState(false)
    const [newAllowance, setNewAllowance] = useState(BigNumber.from(0))

    const [depositCompleted, setDepositCompleted] = useState(false)
    const [newInstAddresses, setNewInstAddresses] = useState(["", "", ""])
    const [newVersion, setNewVersion] = useState(0)
    const [newPosBal, setNewPosBal] = useState(0)

    const depositUsdc = async () => {
        setDepositDisabled(true)
        depositMint(instAddress, depositInput.toString(), instParams).then(
            (tx) => { wrapTx(tx).then(([txReceipt, toastId]) => {
                getPreviewDeposit("1", instParams).then(
                    ([newCounter, _, newAddress, newLong, newShort, newPosSupply]) => {
                        toast.update(toastId,{
                            render: "Mint complete.",
                            type: toast.TYPE.SUCCESS,
                            isLoading: false,
                            autoClose: 3000,
                            hideProgressBar: true
                        })
                        setDepositCompleted(true)
                        setNewInstAddresses([newAddress, newLong, newShort])
                        setNewVersion(newCounter)
                        getPositionBalance(newLong).then((numVal) => {setNewPosBal(numVal)})
                    }
                )

            })}
        )
    }

    const approveUsdc = async () => {
      setApproveDisabled(true)
      usdcApprove().then((tx) => {
          wrapTx(tx).then(([txReceipt, toastId]) => {
              toast.update(toastId,{
                  render: "Approval complete.",
                  type: toast.TYPE.SUCCESS,
                  isLoading: false,
                  autoClose: 3000,
                  hideProgressBar: true
              })
              getUsdcAllowance().then((res) => {
                  setNewAllowance(res)
              })
          })
      })
    }

  if (instParams != undefined) {
    return (

        <div className='Uniswap'>

          <Modal style={{ fontFamily: 'Montserrat' }}
                 size="xl" show={props.show} onHide={() => {
              props.handleClose()

          }}
                 onExited={() => {
                     setApproveDisabled(false)
                     setDepositDisabled(false)
                     setDepositCompleted(false)
                 }}
          >
            <Modal.Header style={{ 'backgroundColor': "#6c7d6d", 'color': 'white' }}>
                <Container >
                    <div style={{padding: "0.2rem"}}>
                <Row className="flex-wrap">
                <div style={{ 'textAlign': "left" }}>
                  <h2>Mint Preview</h2><br />
                </div>
                </Row>
                {/*<div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>*/}
                       <Row className="flex-wrap">
                           <Col className="modal-col"><span className='h4'>Asset: {ADDR_ASSETS_MAP[instParams.underlyingAsset]} </span></Col>
                           <Col className="modal-col"><span className='h4'>Type: {instParams.contractType == 0 ? CALL_PUT_SELECTION.CALL: CALL_PUT_SELECTION.PUT} </span></Col>
                           <Col className="modal-col"><span className='h4'>Strike: {toLocaleComma(formatDecNumber(instParams.strikePrice), 2)} </span></Col>
                  <Col className="modal-col"><span className='h4'>Expiry: {(new Date(`${instParams.year}-${instParams.month}-${instParams.day}`)).toDateString()
                      .split(' ').slice(1).join(' ')} </span></Col>
                {/*</div>*/}
                       </Row>
                <Row className="flex-wrap modal-row">
                        {/*<div style={{ 'display': 'flex', 'justifyContent': 'space-between', marginTop: "1rem"}}>*/}
                            <Col className="modal-col"><span className='h6'>Risk: {`R${instParams.thresholdIndex + 1}`}</span></Col>
                  <Col className="modal-col"><span className='h6'>Threshold: {
                      instParams.contractType == 0 ?
                          toLocaleComma(formatDecNumber(instParams.strikePrice) + RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][instParams.thresholdIndex], 2)
                          :
                          (formatDecNumber(instParams.strikePrice) - RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][instParams.thresholdIndex]) < 0 ?
                              "This instrument cannot be created because the liquidation threshold is negative."
                              :
                              toLocaleComma(formatDecNumber(instParams.strikePrice) - RISK_VALS[ADDR_ASSETS_MAP[instParams.underlyingAsset]][instParams.thresholdIndex], 2)
                  } </span></Col>
                    <Col className="modal-col"><span className='h6'>Latest Version: {counter}</span></Col>
                    <Col className="modal-col"><span className='h6'>Minted Supply: {toLocaleComma(positionSupply, 2)}</span></Col>
                {/*</div>*/}
                        </Row>
                        <Row className="flex-wrap modal-row">
                  {/*<div style={{ 'display': 'flex', 'justifyContent': 'space-between', marginTop:"1rem" }}>*/}
                      <Col className="modal-col"><span className='h5'>
                          Deposit Amount: {
                          toLocaleComma(depositInput, 6)} USDC
                      </span></Col>
                      <Col className="modal-col"><span className='h5'>
                          {activeSigner != undefined ?
                              `From: ${activeSigner._address.slice(0,7)}..${activeSigner._address.slice(-5)}`
                          :
                              `Please connect wallet to continue.`} </span></Col>
                  {/*</div>*/}
                        </Row>

                  {/*<div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>*/}
                        <Row className="flex-wrap modal-row">
                            <Col className="modal-col-borderless">
                      <span className='h6' style={{marginLeft: "0rem"}}>
                          You will receive: {toLocaleComma(amountTokens, 6)} LONG and {toLocaleComma(amountTokens, 6)} SHORT position tokens. </span>
                            </Col>
                  {/*</div>*/}
                        </Row>


              </div>
                </Container>
            </Modal.Header>
            <Modal.Body style={{ 'backgroundColor': "#6c7d6d", 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', 'color': 'white', 'flexDirection': 'column' }}>
                <Container>
                {depositCompleted ?
                    <Row className="flex-wrap ">
                        <Col className="modal-col-borderless ">
                        <Button className="button-width" variant="dark" size="lg"
                                onClick={() => {
                                    props.handleClose()
                                }}
                        >Close</Button>
                        </Col>
                    </Row>
                    :<>
                    <Row className="flex-wrap">

                    {compareBnToNumString(approveDisabled ? newAllowance : userAllowance, depositInput.toString()) ?
                        <Col className="modal-col-borderless ">
                        <Button className="button-width" variant="light" size="lg"
                                disabled={depositDisabled}
                                onClick={() => {
                                    depositUsdc()
                                }}
                        >Mint</Button>
                        </Col>
                        :
                        <Col className="modal-col-borderless ">
                        <Button className="button-width" variant="light" size="lg"
                                disabled={approveDisabled}
                                onClick={() => {
                                    approveUsdc()
                                }}
                        >Approve</Button>
                        </Col>
                    }
                    </Row>
                    <Row className="flex-wrap">
                        <Col className="modal-col-borderless ">
                        <Button className="button-width" variant="dark" size="lg"
                                onClick={() => {
                                    props.handleClose()
                                }}
                        >Close</Button>
                        </Col>
                    </Row>
                </>
                }

                { depositCompleted ?
                    <>
                        <Row className="flex-wrap">
                            <Col className="modal-col-borderless ">
                        {/*<div style={{ 'display': 'flex', 'justifyContent': 'space-between', marginTop: "0rem"}}>*/}
                        <span className="h5">Mint Completed.
                            Your new balance is : {toLocaleComma(newPosBal, 6)} LONG and {toLocaleComma(newPosBal, 6)} SHORT positions tokens.</span>
                            </Col>
                        </Row>
                    {/*</div>*/}
                    {/*    <div style={{ 'display': 'flex', 'justifyContent': 'space-between', marginTop: "1rem"}}>*/}
                        <Row className="flex-wrap">
                        <Col className="modal-col-borderless-sm">
                        <span className="h6"> View on explorer: </span>
                        </Col>
                        </Row><Row className="flex-wrap">
                        <Col className="modal-col-borderless-sm">
                            <span className="h6">
                              <a href={`${EXPLORER_URL}${newInstAddresses[0]}`} target="_blank"
                                 style={{color: "lightblue"}}> Instrument→
                                    </a>
                          </span>
                        </Col>
                        <Col className="modal-col-borderless-sm">
                            <span className="h6">
                              <a href={`${EXPLORER_TOKEN_URL}${newInstAddresses[1]}`} target="_blank"
                                 style={{color: "lightblue"}}> Long Position→
                                    </a>
                          </span>
                        </Col>
                        <Col className="modal-col-borderless-sm">
                            <span className="h6">
                              <a href={`${EXPLORER_TOKEN_URL}${newInstAddresses[2]}`} target="_blank"
                                 style={{color: "lightblue"}}> Short Position→
                                    </a>
                          </span>
                        </Col>
                        </Row>
                        {/*</div>*/}

                        {/*<Row className="flex-wrap">*/}
                        {/*    <Col className="modal-col-borderless">*/}
                        {/*    <Button className="button-width" variant="outline-light" size="sm" onClick={() => {*/}
                        {/*        importTokens(longAddress, shortAddress)*/}
                        {/*    }}>*/}
                        {/*        Import position tokens to Metamask assets list*/}
                        {/*    </Button>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </>
                    :
                    instAddress == ZERO_ADDRESS ?
                        <Row className="flex-wrap">
                            <Col className="modal-col-borderless">
                                <span className='h6'>New instrument will be created.</span>
                            </Col>
                        </Row>
                    :
                    <>
                        {/*<div style={{ 'display': 'flex', 'justifyContent': 'space-between', marginTop: "1rem"}}>*/}
                        <Row className="flex-wrap">
                            <Col className="modal-col-borderless">
                            <span className="h6"> View on explorer: </span>
                            </Col>
                        </Row><Row className="flex-wrap">
                            <Col className="modal-col-borderless">
                                <span className="h6">
                              <a href={`${EXPLORER_URL}${instAddress}`} target="_blank"
                                 style={{color: "lightblue"}}> Instrument→
                                    </a>
                          </span>
                            </Col>
                            <Col className="modal-col-borderless">
                                <span className="h6">
                              <a href={`${EXPLORER_URL}${longAddress}`} target="_blank"
                                 style={{color: "lightblue"}}> Long Position→
                                    </a>
                          </span>
                            </Col>
                            <Col className="modal-col-borderless">
                                <span className="h6">
                              <a href={`${EXPLORER_URL}${shortAddress}`} target="_blank"
                                 style={{color: "lightblue"}}> Short Position→
                                    </a>
                          </span>
                            </Col>
                            </Row>
                        {/*</div>*/}

                        {/*<Row className="flex-wrap">*/}
                        {/*    <Col className="modal-col-borderless">*/}
                        {/*    <Button className="button-width" variant="outline-light" size="sm" onClick={() => {*/}
                        {/*        importTokens(longAddress, shortAddress)*/}
                        {/*    }}>*/}
                        {/*        Import position tokens to Metamask assets list*/}
                        {/*    </Button>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        </>
                }
                </Container>
            </Modal.Body>

          </Modal>
        </div >
    )
  }

  return (
      <>
      </>
  )

}
export default ModalMint;
